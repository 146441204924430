import React, { ReactNode } from 'react';
import {
  ExportButton as ExportButtonType,
  ListField
} from '@applications-terrains/birdz-react-library';
import { Box, Paper, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import useFetchData from '../../../hooks/useFetchData';
import EmptyPageWithLoader from '../Pages/EmptyPageWIthLoader';
import PaginationComponent from './PaginationComponent';
import { useEffect, useState } from 'react';
import PageSizeSelector from './PageSizeSelector';
import EmptyPage from '../Pages/EmptyPage';
import './b-table.scss';
import { omit } from 'lodash';
import { useFetchDataRefsContext } from '../../../contexts/FetchDataRefsContext';
import ExportButton from './export/ExportButton';
import { getFullEndpoint, getPaginatedElements, isFetchEnabled } from './const';
import usePagination from '../../../hooks/usePagination';
import { BTableHeadRow, BTableRow } from './components/TableComponents';
import usePrevious from '../../../hooks/usePrevious';

type TableProps = {
  fields: (ListField & { labelIcon?: ReactNode })[];
  actions?: any[];
  endpoint?: string;
  model: string;
  exportButtons?: (ExportButtonType & { id: string })[];
  refresh?: boolean;
  resultsField?: string;
  selfPaginate?: boolean; //necessary if data is not fetched from the endpoint (ex: if filters are applied)
  extraComponent?: ReactNode;
  onRowsSelect?: (rows: object[]) => void;
  order?: string;
  [key: string]: any;
};

const BTable = ({
  fields,
  actions,
  endpoint,
  model,
  exportButtons,
  resultsField,
  selfPaginate,
  extraComponent,
  onRowsSelect,
  order,
  refresh = false
}: TableProps) => {
  const previousEndpoint = usePrevious(endpoint);
  const [internalLoading, setInternalLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const { dataRefs, setDataRefs } = useFetchDataRefsContext();
  const { pagination, setPagination, resultString } = usePagination({ model });
  const fullEndpoint = endpoint
    ? getFullEndpoint({ selfPaginate, endpoint, pagination, order })
    : '';
  const enableFetch = fullEndpoint ? isFetchEnabled({ dataRefs, model, fullEndpoint }) : false;

  const { isLoading, error, refetch, isFetched } = useFetchData({
    key: model,
    endpoint: fullEndpoint,
    enableFetch,
    resultsField
  });

  const previousSelectedRowsLength = usePrevious(selectedRows.length);

  useEffect(() => {
    if (onRowsSelect && dataRefs[model]?.results) {
      const rows = selectedRows.map((id) => {
        const row = dataRefs[model]?.results.find((row) => (row as { id: number })?.id === id);
        return row as object;
      });
      rows.length !== previousSelectedRowsLength && onRowsSelect(rows);
    }
  }, [onRowsSelect, dataRefs[model]?.results, selectedRows, previousSelectedRowsLength]);

  //ensure a loader is always shown on page change, and cancels it 300ms after data has arrived
  useEffect(() => {
    (pagination.totalItems !== null || isFetched) &&
      setTimeout(() => {
        setInternalLoading(false);
      }, 300);
  }, [pagination, isFetched]);

  useEffect(() => {
    endpoint !== previousEndpoint && setInternalLoading(true);
  }, [endpoint, previousEndpoint]);

  useEffect(() => {
    if (refresh) {
      refetch();
      setInternalLoading(true);
      setPagination((pagination) => ({ ...pagination, totalItems: null }));
      dataRefs && model in dataRefs && setDataRefs(omit(dataRefs, [model]));
    }
  }, [refresh, refetch, model, dataRefs, setDataRefs]);

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.checked) {
      const rows = dataRefs[model]?.results;
      rows && setSelectedRows(rows.map((row) => (row as { id: number }).id)); // Ajouter tous les IDs
    } else {
      setSelectedRows([]); // Réinitialiser la sélection
    }
  };

  // Gérer la sélection individuelle des lignes
  const handleRowSelect = (id: number): void => {
    setSelectedRows(
      (prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((rowId) => rowId !== id) // Retirer si déjà sélectionné
          : [...prevSelected, id] // Ajouter si non sélectionné
    );
  };

  // Vérifier si une ligne est sélectionnée
  const isRowSelected = (id: number): boolean => selectedRows.includes(id);

  return (
    <Paper
      sx={{
        position: 'relative',
        border: '1px solid rgba(224, 224, 224, 1)',
        boxSizing: 'border-box',
        p: 2,
        height: '100%'
      }}
      className="d-flex flex-column table-container"
      elevation={0}
    >
      {dataRefs && dataRefs[model]?.count === 0 ? (
        <EmptyPage message="Aucun résultat" className="transitioned" />
      ) : error ? (
        <EmptyPage message="Oups... Une erreur est survenue" className="transitioned" />
      ) : (
        <>
          <Box
            className="w-100 d-flex align-items-center justify-content-between transitioned"
            sx={{
              pl: 4,
              pr: 2,
              color: '#666666',
              position: 'absolute',
              top: 0,
              left: 0,
              boxSizing: 'border-box'
            }}
          >
            {resultString}{' '}
            {selectedRows.length > 0 &&
              `(${selectedRows.length} sélectionné${selectedRows.length > 1 ? 's' : ''})`}
            <Box minHeight={50} className="d-flex align-items-center">
              {exportButtons &&
                exportButtons.length > 0 &&
                exportButtons.map((exportButton, index) => (
                  <ExportButton
                    key={exportButton.name + index}
                    exportButton={exportButton}
                    endpoint={exportButton.requestEndpoint || ''}
                    pagination={pagination}
                  />
                ))}
            </Box>
          </Box>

          <TableContainer
            className="transitioned"
            sx={{
              mt: 4,
              borderTop: '1px solid rgba(224, 224, 224, 1)',
              boxSizing: 'border-box'
            }}
          >
            {extraComponent}
            <Table
              sx={
                extraComponent
                  ? {
                      mt: 2,
                      borderTop: '1px solid rgba(224, 224, 224, 1)',
                      boxSizing: 'border-box'
                    }
                  : {}
              }
              size="small"
              stickyHeader
            >
              <TableHead>
                <BTableHeadRow
                  fields={fields}
                  actions={actions}
                  handleSelectAll={onRowsSelect && handleSelectAll}
                  selected={selectedRows.length === dataRefs[model]?.results.length}
                  indeterminate={
                    selectedRows.length > 0 &&
                    selectedRows.length < (dataRefs[model]?.results.length as number)
                  }
                />
              </TableHead>
              <TableBody className="" sx={{ overflow: 'auto' }}>
                {(selfPaginate
                  ? getPaginatedElements({
                      data: dataRefs?.[model]?.results || [],
                      pageNumber: pagination.currentPage,
                      pageSize: pagination.pageSize
                    })
                  : dataRefs[model]?.results
                )?.map((row: any, index: number) => (
                  <BTableRow
                    row={row}
                    index={index}
                    fields={fields}
                    actions={actions}
                    key={'tableRow' + row.name + index}
                    selected={isRowSelected(row.id)}
                    onRowSelect={onRowsSelect && handleRowSelect}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {(dataRefs[model]?.count ?? 0) > 5 && (
            <Box className="w-100 d-flex flex-grow-1 flex-column">
              <Box
                className="BTable-footer d-flex justify-content-between align-items-center"
                sx={{
                  pt: 2,
                  mt: 'auto'
                }}
              >
                <PaginationComponent
                  pagination={pagination}
                  setPagination={(val) => {
                    setPagination(val);
                  }}
                />
                <PageSizeSelector pagination={pagination} setPagination={setPagination} />
              </Box>
            </Box>
          )}
        </>
      )}
      {(isLoading || internalLoading) && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)'
          }}
        >
          <EmptyPageWithLoader />
        </Box>
      )}
    </Paper>
  );
};

export default BTable;
