import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Grid,
  Paper,
  ImageList,
  ImageListItem,
  Modal,
  Alert,
  Stack,
  Pagination
} from '@mui/material';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import { Media } from '../../Informations/InformationDetails';
import { useFormikContext } from 'formik';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useParams } from 'react-router-dom';
import {
  BirdzDialog,
  BirdzNotif,
  useDialog,
  useNotif
} from '@applications-terrains/birdz-react-library';
import { InterventionEventsHelper } from '../utils/EventsHelper';
import { authService } from '../../..';
import { FileField } from './MediaUpload';

export default function InterventionMedia({ callRefresh }: any) {
  const [modalMedia, setModalMedia] = useState<Media>();
  const { values }: any = useFormikContext();
  const [media, setMedia] = useState<Media[]>();
  const { id } = useParams();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const [mediaHovered, setMediaHovered] = useState<string>();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items to display per page

  const offset = (currentPage - 1) * itemsPerPage;
  const currentPageData = media?.slice(offset, offset + itemsPerPage);

  useEffect(() => {
    setMedia(values?.media || []);
  }, []);

  const deleteMedia = async (index: number) => {
    const globalIndex = (currentPage - 1) * itemsPerPage + index;
    const endpoint = authService.getEndpoint(
      `/api/boi/cases/technician-feedback/${id}/delete-media/`,
      `/api/boe/cases/cr-interventions-details/${id}/delete-media/`
    );

    if (media) {
      confirmDialog({
        title: 'Supprimer le média',
        content: 'Êtes-vous sûr de vouloir supprimer ce média?',
        onValidate: () => {
          axios
            .post(endpoint, {
              media_id: media[globalIndex].id
            })
            .then(
              () => {
                notif({
                  content: 'Le média a été supprimé avec succès',
                  type: 'success'
                });
                const newMedia = media.filter((item) => item.id !== media[globalIndex].id);
                setMedia(newMedia);
              },
              () => {
                notif({
                  content: 'Une erreur est survenue lors de la suppression',
                  type: 'error'
                });
              }
            );
          closeDialog();
        },
        onCancel: () => {
          closeDialog();
        }
      });
    }
  };

  const isVideo = (mimeType: string) => {
    return mimeType.substring(0, 5) === 'video';
  };

  return (
    <Paper variant="outlined" square sx={{ mt: 2 }}>
      <Accordion defaultExpanded sx={{ boxShadow: 'none' }} disableGutters={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h3>Média</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12}>
              {!media || media?.length === 0 ? (
                <Alert severity="info" sx={{ mt: 0 }}>
                  Aucun média disponible
                </Alert>
              ) : (
                <>
                  <ImageList cols={5} rowHeight={200}>
                    <>
                      {currentPageData?.map((media, index) => {
                        return (
                          <ImageListItem
                            sx={{ cursor: 'pointer' }}
                            key={media.uuid}
                            onClick={() => setModalMedia(media)}
                          >
                            {isVideo(media.original_mime_type) && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: 0,
                                  left: 0,
                                  right: 0,
                                  bottom: 0
                                }}
                              >
                                <Stack
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="center"
                                  gap={1}
                                  sx={{ height: '100%' }}
                                >
                                  <PlayCircleOutlineIcon
                                    sx={{ color: 'rgba(255,255,255,0.75)', fontSize: '150px' }}
                                  />
                                </Stack>
                              </div>
                            )}
                            <Box
                              onMouseOver={() => setMediaHovered(media.uuid)}
                              onMouseOut={() => setMediaHovered(undefined)}
                            >
                              <img src={media.thumbnail_signed_url} alt="" width="100%" />
                              {!InterventionEventsHelper.hasClosedEvent(values.events_history) &&
                                mediaHovered === media.uuid && (
                                  <IconButton
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deleteMedia(index);
                                    }}
                                    sx={{
                                      position: 'absolute',
                                      top: 10,
                                      right: 10,
                                      zIndex: 10,
                                      color: 'white'
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                )}
                            </Box>
                          </ImageListItem>
                        );
                      })}
                    </>
                  </ImageList>
                  <Modal
                    open={modalMedia !== undefined}
                    onClose={() => setModalMedia(undefined)}
                    style={
                      {
                        // display: 'flex',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                      }
                    }
                  >
                    <Box
                      sx={{
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                      onClick={() => setModalMedia(undefined)}
                    >
                      {modalMedia && modalMedia !== null && (
                        <>
                          {
                            // Images files
                            !isVideo(modalMedia.original_mime_type) && (
                              <img
                                src={modalMedia?.original_signed_url}
                                alt=""
                                style={{ maxWidth: '90%', maxHeight: '90%' }}
                              />
                            )
                          }

                          {
                            // Video files
                            isVideo(modalMedia.original_mime_type) && (
                              <Box sx={{ m: 3 }}>
                                <video controls width="100%">
                                  <source
                                    src={modalMedia.original_signed_url}
                                    type={modalMedia.original_mime_type}
                                  />
                                </video>
                              </Box>
                            )
                          }
                        </>
                      )}
                    </Box>
                  </Modal>
                  {media.length > itemsPerPage && (
                    <Stack alignContent={'flex-end'} alignItems={'end'}>
                      <Pagination
                        count={Math.ceil(media.length / itemsPerPage)}
                        onChange={(event: any, page: number) => {
                          setCurrentPage(page);
                        }}
                      />
                    </Stack>
                  )}
                </>
              )}
            </Grid>
            {authService.canAccessBOE() && (
              <Grid item xs={12} sx={{ mt: !media || !media.length ? 1 : 0 }}>
                {(values.status === 1 ||
                  values.status === 2 ||
                  values.status === 3 ||
                  values.status === 6) && (
                  <FileField
                    id={'media-upload-' + `${values.id}`}
                    interId={id}
                    accept="image/*"
                    choseFileLabel="Choisir une photo"
                    onRefresh={() => callRefresh && callRefresh()}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <BirdzDialog options={dialogOptions} />
      <BirdzNotif options={notifOptions} />
    </Paper>
  );
}
