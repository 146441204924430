import moment from 'moment';

// Fonction pour formater la valeur
export const formatValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.map((item) => item.label).join(', ');
  }
  if (typeof value === 'object') {
    return value.label;
  }
  if (typeof value === 'string') {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateRegex.test(value)) {
      return moment(value).format('DD/MM/YYYY');
    } else return value;
  }
  return JSON.stringify(value);
};

export const translateKey = (key: string) => {
  switch (key) {
    case 'agent':
      return 'Agent';
    case 'anomaly_status':
      return 'Statut anomalie';
    case 'anomaly_type':
      return "Type d'anomalie";
    case 'city':
      return 'Commune';
    case 'client':
      return 'Client';
    case 'closing_at_after':
      return 'Réalisation après';
    case 'closing_at_before':
      return 'Réalisation avant';
    case 'contract_id':
      return 'Contrat';
    case 'counter':
      return 'Capteur';
    case 'created_at_after':
      return 'Création après';
    case 'created_at_before':
      return 'Création avant';
    case 'device_id':
      return 'Module';
    case 'equipment_parent':
      return 'Equipement parent';
    case 'exported_at_after':
      return 'Exportée après';
    case 'exported_at_before':
      return 'Exportée avant';
    case 'intervention_type__in':
      return "Type d'intervention";
    case 'module_family':
      return 'Famille de module';
    case 'pds':
      return 'PDS Client';
    case 'reference':
      return 'Référence';
    case 'updated_at_after':
      return 'Mise à jour après';
    case 'updated_at_before':
      return 'Mise à jour avant';
    case 'validation_status':
      return 'Statut validation';
    default:
      return key;
  }
};
