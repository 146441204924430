import React, { ReactNode, createContext, useContext, useState } from 'react';

export type DataRefsType = {
  [key: string]: {
    count: number;
    endpoint?: string;
    next?: string | null;
    previous?: string | null;
    results: object[];
  } | null;
};

type FetchDataRefsContextType = {
  loadingStatus: { key: string; isLoading: boolean };
  setLoadingStatus: ({ key, isLoading }: { key: string; isLoading: boolean }) => void;
  dataRefs: DataRefsType;
  setDataRefs: (data: DataRefsType) => void;
};

const FetchDataRefsContext = createContext<FetchDataRefsContextType | undefined>(undefined);

export const useFetchDataRefsContext = () => {
  const context = useContext(FetchDataRefsContext);

  if (context === undefined) {
    throw new Error('useFetchDataRefsContext must be used with a FetchDataRefsContext');
  }

  return context;
};

export const FetchDataRefsContextProvider = ({ children }: { children: ReactNode }) => {
  const [dataRefs, setDataRefs] = useState<DataRefsType>({});
  const [loadingStatus, setLoadingStatus] = useState<{ key: string; isLoading: boolean }>({
    key: '',
    isLoading: false
  });

  const value = {
    dataRefs,
    setDataRefs,
    loadingStatus,
    setLoadingStatus
  };

  return <FetchDataRefsContext.Provider value={value}>{children}</FetchDataRefsContext.Provider>;
};
