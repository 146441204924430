import React, { JSX, ReactNode, useEffect, useState } from 'react';
import { FormValues, ParamsConfigType } from './Types';
import axios from 'axios';
import * as Yup from 'yup';
import { InputField, ListPage } from '@applications-terrains/birdz-react-library';
import { paramsConfig } from './params.config';
import { Box } from '@mui/material';
import ModalWithLoader, { RequestStatus } from '../../Tools/ModalWithLoader/ModalWithLoader';

type ParamFormProps = {
  param: string;
};

const ParamForm = ({ param }: ParamFormProps) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const defaultOrder = 'id';
  const defaultListFields = [
    {
      name: 'id',
      label: 'ID',
      options: {
        renderFormAdd: () => <></>,
        renderFormEdit: (row: any) => <>{row.id}</>
      }
    }
  ];

  const [paramConfig, setParamConfig] = useState<ParamsConfigType>();
  const [order, setOrder] = useState<string>(defaultOrder);
  const [customErrorMessage, setCustomErrorMessage] = useState<ReactNode>();
  const [action, setAction] = useState<'create' | 'edit' | 'delete' | null>(null);

  const [listFields, setListFields] = useState<any>(defaultListFields);

  useEffect(() => {
    const foundParamConfig = paramsConfig.find((paramConfig) => paramConfig.value === param);
    if (foundParamConfig) {
      setParamConfig(foundParamConfig);

      const newListFields = [...defaultListFields];
      let newOrder = defaultOrder;
      if (foundParamConfig.extraFields) {
        foundParamConfig.extraFields.forEach((extraField) => {
          const newField: any = Object.assign({}, extraField);
          newField.options = {
            renderForm: () => (
              <InputField
                size="small"
                label={extraField.label}
                name={extraField.name}
                type={extraField.type}
              />
            )
          };
          newListFields.push(newField);

          if (extraField.name === 'order') {
            newOrder = 'order';
          }
        });
      }

      setOrder(newOrder);
      setListFields(newListFields);
    }
    //eslint-disable-next-line
  }, [param]);

  if (!paramConfig) {
    return null;
  }

  const onSave = (values: FormValues) => {
    setRequestStatus('pending');
    if (values?.id) {
      setAction('edit');
      return axios.put(`${paramConfig.endpoint}${values.id}`, values).then(
        () => {
          setRequestStatus('success');
        },
        (error) => {
          setRequestStatus('error');
          let errors = error?.response?.data ?? [];
          if (errors) {
            errors = Object.keys(errors).map(
              (fieldError) => fieldError + ': ' + errors[fieldError].join(',')
            );
          }
          setCustomErrorMessage(
            <>
              Une erreur est survenue lors de la modification
              <br />
              <ul>
                {errors.map((error: string) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            </>
          );
        }
      );
    } else {
      setAction('create');
      return axios.post(paramConfig.endpoint, values).then(
        () => {
          setRequestStatus('success');
        },
        (error) => {
          setRequestStatus('error');
          let errors = error?.response?.data ?? [];
          if (errors) {
            errors = Object.keys(errors).map(
              (fieldError) => fieldError + ': ' + errors[fieldError].join(',')
            );
          }
          setCustomErrorMessage(
            <>
              Une erreur est survenue lors de la création
              <br />
              <ul>
                {errors.map((error: string) => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            </>
          );
        }
      );
    }
  };

  const onDelete = (values: FormValues) => {
    setAction('delete');
    setRequestStatus('pending');
    return axios
      .delete(paramConfig?.endpoint + values.id + '/')
      .then(() => {
        setRequestStatus('success');
      })
      .catch((err) => {
        setRequestStatus('error');
        const errMessage = err?.response?.data?.message;
        errMessage && setCustomErrorMessage(errMessage);
      });
  };

  const ParamComponent = (): JSX.Element => {
    const validationSchema: { [key: string]: any } = {};
    listFields.forEach((field: any) => {
      if (field.validationSchema) {
        validationSchema[field.name] = field.validationSchema;
      }
    });

    return (
      <Box id={paramConfig?.value}>
        <ListPage
          endpoint={paramConfig?.endpoint}
          defaultOrder={[order]}
          fields={listFields}
          inlineEditOptions={{
            onSave: onSave,
            onDelete: onDelete,
            validationSchema: Yup.object().shape(validationSchema)
          }}
        />
      </Box>
    );
  };

  const handleCloseModalWithLoader = () => {
    setAction(null);
    setRequestStatus(null);
  };

  return (
    <>
      <ModalWithLoader
        customErrorMessage={customErrorMessage}
        openModal={requestStatus === 'pending'}
        onClose={() => handleCloseModalWithLoader()}
        onSuccess={() => handleCloseModalWithLoader()}
        action={`${action === 'delete' ? 'Suppression' : action === 'edit' ? 'Modification' : 'Création'} du paramètre`}
        status={requestStatus}
        setStatus={setRequestStatus}
      />
      <ParamComponent />
    </>
  );
};

export default ParamForm;
