import React from 'react';
import { Autocomplete, Box, CircularProgress, IconButton, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { FieldType } from '../types';
import SearchableSelect from './Searchable/SearchableSelect';
import ClearIcon from '@mui/icons-material/Clear';
import useFetchData from '../../../../hooks/useFetchData';
import { uniqBy } from 'lodash';

const BSearchField = ({
  field,
  values,
  setFieldValue,
  disabled
}: FieldType & { disabled: boolean }) => {
  if (field.type === 'blank') {
    return <Box></Box>;
  } else if (field.type === 'divider') {
    return (
      <Box
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          my: 1,
          mx: 25
        }}
      ></Box>
    );
  } else {
    let element = (
      <Box className="d-flex align-items-center" sx={{ position: 'relative' }}>
        <TextField
          id={field.name}
          name={field.name}
          label={field.label}
          value={values[field.name] || ''}
          onChange={(e) => setFieldValue(field.name, e.target.value)}
          variant="outlined"
          fullWidth
          size="small"
        />

        {!field.name.includes('__in') && values[field.name] && (
          <IconButton
            size="small"
            onClick={() => setFieldValue(field.name, '')}
            sx={{
              position: 'absolute',
              right: field.options ? 28 : 8
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    );

    if (field.options?.values) {
      element = (
        <Autocomplete
          size="small"
          value={values[field.name] || (field.multiple ? [] : null)}
          options={field.options.values.sort((a, b) => a?.label?.localeCompare(b?.label))}
          disableCloseOnSelect
          onChange={(_, newValue) => setFieldValue(field.name, newValue)}
          renderInput={(params) => <TextField {...params} label={field.label} />}
          isOptionEqualToValue={(option, value) => option.label === value?.label}
          getOptionLabel={(option) => {
            return option.label || '';
          }}
          multiple={field.multiple === true}
          fullWidth
        />
      );
    }

    if (field.options?.source) {
      if (field.queryParamsSourceField) {
        const paramsValues = values[field.queryParamsSourceField.key].map(
          (el: { label: string; value: string | number }) => el.value
        );

        const param =
          paramsValues.length > 1
            ? field.queryParamsSourceField.param
            : field.queryParamsSourceField.param.replace('__in', '');

        const { data, isLoading } = useFetchData({
          key: field.name,
          endpoint: field.options.source + '?' + param + '=' + paramsValues.join(),
          enableFetch: !disabled
        });

        const options = uniqBy(
          data?.results
            .map((el: { [key: string]: string | number }) => ({
              value: el[field.objectFormat?.value as keyof typeof el],
              label: el[field.objectFormat?.label as keyof typeof el]
            }))
            .sort(
              (
                a: { label: string; value: string | number },
                b: { label: string; value: string | number }
              ) => a.label.localeCompare(b.label)
            ) || [],
          'value'
        );

        element = (
          <Autocomplete
            size="small"
            value={values[field.name] || (field.multiple ? [] : null)}
            options={options}
            disabled={disabled}
            disableCloseOnSelect
            onChange={(_, newValue) => setFieldValue(field.name, newValue)}
            renderInput={(params) => <TextField {...params} label={field.label} />}
            isOptionEqualToValue={(option, value) => option.label === value?.label}
            getOptionLabel={(option) => {
              return option.label || '';
            }}
            multiple={field.multiple === true}
            loading={isLoading}
            loadingText={
              <Box className="d-flex align-items-center">
                recherche en cours... <CircularProgress size={16} sx={{ ml: 1 }} />
              </Box>
            }
            fullWidth
          />
        );
      } else if (field.searchable) {
        element = (
          <SearchableSelect
            field={field}
            endpoint={field.options?.source}
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      }
    }

    if (field.type === 'datepicker') {
      const value = values[field.name];
      element = (
        <DatePicker
          className="w-100"
          onChange={(value: any) => {
            setFieldValue(
              field.name,
              value ? moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD') : null
            );
          }}
          label={field.label}
          value={value && typeof value === 'string' ? new Date(value) : null}
          slotProps={{
            field: { clearable: true },
            textField: { variant: 'outlined', size: 'small' }
          }}
        />
      );
    }

    return element;
  }
};

export default BSearchField;
