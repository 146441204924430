import React, { useEffect } from 'react';
import { Marker, FeatureGroup, Polyline, Popup, useMap } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import { TourDevice, TourRoute } from '../../types';
import { Box } from '@mui/material';
import { createGradientPolylines, deviceStatuses, markers } from '../TourMapUtils';
import MarkerClusterGroup from 'react-leaflet-cluster';
import ReadingState from '../../TourDetails/ReadingState';

import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

export const MapHelper = ({ bounds }: { bounds: L.LatLngBounds | undefined }) => {
  const map = useMap();
  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds);
    }
  }, [map, bounds]);

  return null;
};

export const MapRoute = ({ route }: { route?: TourRoute[] }) => {
  return route && route.length > 1 ? (
    <>
      {createGradientPolylines(
        route.map((routePoint) => [routePoint.latitude, routePoint.longitude])
      ).map((segment, index) => (
        <Polyline
          key={index}
          positions={segment.positions}
          pathOptions={{ color: segment.color, weight: 4 }}
        />
      ))}
      <MarkerClusterGroup>
        <Marker icon={markers['start']} position={[route[0].latitude, route[0].longitude]} />
        <Marker
          icon={markers['stop']}
          position={[route[route.length - 1].latitude, route[route.length - 1].longitude]}
        />
      </MarkerClusterGroup>
    </>
  ) : null;
};

export const MapDevices = ({
  devices,
  showDevices
}: {
  showDevices?: boolean;
  devices: TourDevice[];
}) => {
  return (
    <MarkerClusterGroup>
      {showDevices &&
        devices.map((device, index) => {
          return (
            <Marker
              icon={
                markers[
                  device.reading_state && device.reading_state in markers
                    ? device.reading_state
                    : 'default'
                ]
              }
              key={
                device.COORD_X + device.COORD_Y + index + device.device_id ||
                device.MODULE ||
                device.COMPTEUR
              }
              position={[parseFloat(device.COORD_Y), parseFloat(device.COORD_X)]}
            >
              <Popup>
                {device.NUM_RUE_ABO ?? '-'} {device.ATTR_NUM_RUE_ABO ?? ''} {device.RUE_ABO ?? '-'}{' '}
                <br />
                {device.COMMUNE_ABO ?? '-'} <br />
                <br />
                <Box sx={{ fontWeight: 600, mt: 1 }}>Module</Box>
                {device.MODULE || device.device_id || '-'} <br />
                <Box sx={{ fontWeight: 600, mt: 1 }}>Statut</Box>
                {device?.reading_state && device?.reading_state in deviceStatuses ? (
                  <ReadingState value={device.reading_state} />
                ) : (
                  '-'
                )}
                <br />
                <Box sx={{ fontWeight: 600, mt: 1 }}>Types de relevés</Box>
                {device.results?.collected_frames?.map((el) => el.frame_type_id).join(',') ||
                  '-'}{' '}
                <br />
              </Popup>
            </Marker>
          );
        })}
    </MarkerClusterGroup>
  );
};

export const MapDrawHandler = ({
  onSelected,
  devices
}: {
  onSelected?: (value: TourDevice[]) => void;
  devices: TourDevice[];
}) => {
  const map = useMap();

  // Référence à la dernière forme créée
  let currentLayer: L.Layer | null = null;

  const handleDraw = (
    e: L.DrawEvents.Created | L.DrawEvents.Edited | L.DrawEvents.Deleted,
    action: 'create' | 'edit' | 'delete'
  ) => {
    if (action === 'delete') {
      onSelected && onSelected([]);
      currentLayer = null;
    } else {
      const processLayer = (layer: L.Layer & { getBounds?: () => L.LatLngBounds }) => {
        const bounds = layer.getBounds?.();
        if (bounds) {
          const selectedDevices = devices.filter((device) =>
            bounds.contains([parseFloat(device.COORD_Y), parseFloat(device.COORD_X)])
          );
          onSelected &&
            onSelected(
              selectedDevices.map((device) =>
                'data' in device
                  ? {
                      ...device,
                      ...device.data,
                      MODULE: device.MODULE || device.device_id,
                      device_id: device.MODULE || device.device_id
                    }
                  : device
              )
            );
        }
      };

      if (action === 'create') {
        const layer = (e as L.DrawEvents.Created).layer as L.Layer & {
          getBounds?: () => L.LatLngBounds;
        };

        // Supprimer la forme existante si elle est présente
        if (currentLayer) {
          map?.removeLayer(currentLayer);
        }

        processLayer(layer);
        currentLayer = layer; // Mettre à jour la référence à la nouvelle forme créée
      } else if (action === 'edit') {
        const editedLayers = (e as L.DrawEvents.Edited).layers as L.LayerGroup;
        editedLayers.eachLayer((layer: L.Layer & { getBounds?: () => L.LatLngBounds }) => {
          processLayer(layer);
        });
      }
    }
  };

  return onSelected ? (
    <FeatureGroup>
      <EditControl
        position="topright"
        onCreated={(e) => handleDraw(e, 'create')}
        onEdited={(e) => handleDraw(e, 'edit')}
        onDeleted={(e) => handleDraw(e, 'delete')}
        draw={{
          rectangle: true,
          polyline: true,
          circle: false,
          circlemarker: false,
          marker: false,
          polygon: false
        }}
      />
    </FeatureGroup>
  ) : null;
};
