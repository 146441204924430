import React from 'react';
import { ListPage, AppPaper, BirdzTitle } from '@applications-terrains/birdz-react-library';
import moment from 'moment';
import { Link } from 'react-router-dom';
import SubcontractorsForm from './SubcontractorsForm';
import { Button, Grid, IconButton } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';

const Subcontractors = (params: any) => {
  const endpoint = '/api/boi/subcontractors/subcontractors/?ordering=name&';
  const listFields = [
    { name: 'id', label: '#', options: { width: '30px' } },
    {
      name: 'name',
      label: 'Nom société',
      className: 'text-center',
      options: { width: '200px' },
      orderable: true
    },
    {
      name: 'created_at',
      label: 'Date création',
      orderable: true,
      transform: (value: any) => {
        return (value && moment.unix(value).format('DD/MM/YYYY à HH:mm:ss')) || '';
      },
      options: { width: '200px' }
    },
    {
      name: 'action_type',
      label: 'Type',
      className: 'text-center',
      options: { width: '150px' }
    },
    {
      name: 'main_contact',
      label: 'Contact principal',
      className: 'text-center',
      transform: (value: string) => {
        return value ?? '-';
      }
    },
    {
      name: 'technicians_count',
      label: 'NB techniciens',
      className: 'text-center'
    }
  ];

  const searchFields = [
    {
      name: 'name',
      id: 'search_by_name',
      label: 'Nom société',
      options: {
        source: endpoint,
        identifier: 'name',
        searchIsMulti: false
      },
      className: 'col-sm-6'
    },
    {
      name: 'action_type',
      id: 'search_by_type',
      label: 'Type',
      options: {
        values: [
          { value: 'MR', label: 'MR' },
          { value: 'CR', label: 'CR' }
        ],
        searchIsMulti: false
      },
      className: 'col-sm-6'
    },
    {
      name: 'technicians',
      id: 'search_by_technician',
      label: 'Nom technicien',
      options: {
        identifier: 'user',
        label: 'user__name',
        source: '/api/boi/subcontractors/technicians/?ordering=user__name&',
        searchIsMulti: false
      },
      className: 'col-sm-6'
    }
  ];

  const actions = [
    {
      name: 'detail',
      label: 'Détail',
      render: (subcontractor: any) => {
        return (
          <IconButton
            component={Link}
            to={`/boi/administration/subcontractors/edit/${subcontractor.id}`}
          >
            <SearchIcon fontSize="small" />
          </IconButton>
        );
      }
    }
  ];

  return (
    <AppPaper>
      {params.action === 'list' && (
        <>
          <Grid justifyContent="space-between" container>
            <Grid item>
              <BirdzTitle>Liste des sous-traitants</BirdzTitle>
            </Grid>
            <Grid item>
              <Link to="/boi/administration/subcontractors/create">
                <Button variant="contained" sx={{ mt: 3 }}>
                  <AddCircleIcon sx={{ mr: 1 }} /> Ajouter un sous-traitant
                </Button>
              </Link>
            </Grid>
          </Grid>

          <ListPage
            endpoint={endpoint}
            fields={listFields}
            actions={actions}
            searchFields={searchFields}
          />
        </>
      )}
      {params.action === 'add' && <SubcontractorsForm action="add" />}
      {params.action === 'edit' && <SubcontractorsForm action="edit" />}
    </AppPaper>
  );
};
export default Subcontractors;
