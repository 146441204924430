import L from 'leaflet';

import defaultMarker from '../../../../../../assets/icons/map/location_default.svg';
import failureMarker from '../../../../../../assets/icons/map/location_failure.svg';
import collectedMarker from '../../../../../../assets/icons/map/location_collected.svg';
import to_collectMarker from '../../../../../../assets/icons/map/location_to_collect.svg';
import partiallyMarker from '../../../../../../assets/icons/map/location_partially.svg';
import stopMarker from '../../../../../../assets/icons/map/route_ending_point.svg';
import startMarker from '../../../../../../assets/icons/map/route_starting_point.svg';

const getIcon = (icon: string) =>
  new L.Icon({
    iconUrl: icon,
    iconRetinaUrl: icon,
    popupAnchor: [-0, -0],
    iconSize: [32, 32]
  });

export const markers = {
  default: getIcon(defaultMarker),
  FAILURE: getIcon(failureMarker),
  COLLECTED: getIcon(collectedMarker),
  TOCOLLECT: getIcon(to_collectMarker),
  PARTIALLY: getIcon(partiallyMarker),
  stop: getIcon(stopMarker),
  start: getIcon(startMarker)
};

export const deviceStatuses = {
  FAILURE: 'en erreur',
  COLLECTED: 'collecté',
  TOCOLLECT: 'à collecter',
  PARTIALLY: 'partiellement collecté',
  default: 'non renseigné'
};

export const createGradientPolylines = (points: [number, number][]) => {
  return points.slice(1).map((point, index) => ({
    positions: [points[index], point],
    color: `hsl(${(index * 300) / points.length}, 100%, 50%)`
  }));
};
