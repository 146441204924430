import React from 'react';
import { Box } from '@mui/material';

const EmptyPage = ({ message, className }: { message: string; className?: string }) => {
  return (
    <Box
      className={`w-100 d-flex flex-grow-1 align-items-center justify-content-center ${className ? className : ''}`}
    >
      {message}
    </Box>
  );
};

export default EmptyPage;
