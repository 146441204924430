import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useFetchDataRefsContext } from '../contexts/FetchDataRefsContext';

export type FetchDataObject = {
  key: string;
  endpoint: string;
  enableFetch: boolean;
  resultsField?: string;
};

const useFetchData = ({ key, endpoint, enableFetch, resultsField }: FetchDataObject) => {
  const { dataRefs, setDataRefs, setLoadingStatus } = useFetchDataRefsContext();

  return useQuery({
    queryKey: [key, endpoint],
    enabled: enableFetch, // Utilise le paramètre enableFetch pour activer/désactiver le fetch
    queryFn: async () => {
      setLoadingStatus({ key, isLoading: true });
      const { data } = await axios.get(endpoint);
      const dataObj =
        resultsField && resultsField in data
          ? { endpoint, count: data[resultsField].length, results: data[resultsField] }
          : data.results
            ? { endpoint, ...data }
            : { endpoint, count: data.length, results: data };

      // Met à jour le contexte des références

      setDataRefs({
        ...dataRefs,
        [key]: dataObj
      });

      setLoadingStatus({ key, isLoading: false });
      return dataObj;
    },
    initialData: enableFetch ? undefined : dataRefs[key] // Fournit une donnée initiale si `enableFetch` est faux
  });
};

export default useFetchData;
