import { Box, LinearProgress, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetInterventionStatuses } from '../../../hooks/datarefs';

type InterventionStatusProps = {
  interventionStatusId: number;
};
type InterventionStatusType = {
  id: number;
  label: string;
  order: number;
};

const InterventionStatus = ({ interventionStatusId }: InterventionStatusProps) => {
  const { data: interventionStatuses } = useGetInterventionStatuses();
  const [interventionStatusProgressValue, setInterventionStatusProgressValue] = useState<number>(0);

  useEffect(() => {
    if (!interventionStatuses) return;

    const position = interventionStatuses
      .map((interventionStatuse: any) => interventionStatuse.id)
      .indexOf(interventionStatusId);
    setInterventionStatusProgressValue((position / (interventionStatuses.length - 1)) * 100);
  }, [interventionStatusId, interventionStatuses]);

  return (
    <>
      {interventionStatuses && interventionStatuses.length > 0 && (
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={4}>
            {(interventionStatuses as InterventionStatusType[])
              .sort((a, b) => a.order - b.order)
              .map((interventionStatus: InterventionStatusType, index: number) => (
                <div key={'interventionStatus-' + interventionStatus.label + '-' + index}>
                  {interventionStatus.label}
                </div>
              ))}
          </Stack>
          <LinearProgress
            variant="determinate"
            color="inherit"
            value={interventionStatusProgressValue}
            sx={{ height: '14px', mb: 2 }}
          />
        </Box>
      )}
    </>
  );
};
export default InterventionStatus;
