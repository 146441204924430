import { Clear, Upload } from '@mui/icons-material';
import { Box, Button, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';

export const FileField = ({
  interId,
  accept,
  choseFileLabel = 'Choisir un fichier',
  onRefresh = () => Promise<void>
}: any) => {
  const [fieldValue, setFieldValue] = useState<any>();

  const { notif, notifOptions } = useNotif();

  const toBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const base64ToBlob = (base64: any, contentType = 'image/png') => {
    // Supprimer le préfixe MIME si nécessaire
    const base64Data = base64.split(',')[1]; // Supprime le préfixe 'data:image/png;base64,'
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const uploadMedia = async () => {
    const blob = base64ToBlob(fieldValue.base64, 'image/png');

    axios
      .post(`/api/boe/cases/cr-intervention/${interId}/file-upload/media/`, blob, {
        headers: {
          'Content-Type': fieldValue.fileType,
          'Content-Disposition': 'attachment; filename=' + fieldValue.fileName,
          'Media-Upload': true
        }
      })
      .then(() => {
        notif({
          type: 'success',
          content: 'Le média a été ajouté avec succès'
        });
        setFieldValue('');
        onRefresh && onRefresh();
      })
      .catch(() => {
        notif({
          type: 'error',
          content: `Erreur lors de l'ajout du média`
        });
      });
  };

  return (
    <>
      <span>
        {fieldValue ? (
          <>
            <Box>
              <img src={fieldValue.base64} width="200px" alt="New media test" />
            </Box>
            <Grid
              item
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                width: '200px'
              }}
            >
              <Button
                onClick={() => setFieldValue('')}
                size="small"
                variant="outlined"
                color="error"
                sx={{ mt: 1 }}
                id={`clear_file_field_${fieldValue.fileName}`}
              >
                <Clear />
              </Button>
              <Button
                onClick={() => uploadMedia()}
                size="small"
                variant="outlined"
                color="success"
                sx={{ mt: 1, ml: 1 }}
                id={`add_file_${fieldValue.fileName}`}
              >
                <CheckIcon />
              </Button>
            </Grid>
          </>
        ) : (
          <Button variant="outlined" component="label" id={`choose_file_field_${interId}`}>
            <Upload sx={{ mr: 1 }} /> {choseFileLabel}
            <TextField
              margin="dense"
              id="file"
              type="file"
              fullWidth
              variant="standard"
              hidden
              inputProps={{ accept }}
              sx={{ display: 'none' }}
              onChange={async (event: any) => {
                if (event.target.files && event.target.files.length) {
                  const file = event.target.files[0];
                  if (file.type.split('/')[0] !== 'image') {
                    return;
                  }

                  const selectedFile = event.target.files?.length ? event.target.files[0] : null;
                  if (selectedFile) {
                    const fileBase64 = await toBase64(selectedFile);
                    setFieldValue({
                      base64: fileBase64,
                      fileName: selectedFile.name,
                      fileType: selectedFile.type
                    });
                  }
                }
              }}
            />
          </Button>
        )}
        {/* {fieldError && (
          <FormHelperText sx={{ color: '#d32f2f', ml: '14px' }}>{fieldError}</FormHelperText>
        )} */}
      </span>
      <BirdzNotif options={notifOptions} />
    </>
  );
};
