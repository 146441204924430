import React, { useEffect, useState } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Box } from '@mui/material';

const CustomLabel = ({ cx, cy, midAngle, percent, displayMode, value }: any) => {
  const RADIAN = Math.PI / 180;
  const radius = 130;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    //todo displayMode none
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {displayMode === 'values' ? `${value}` : `${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

type PieData = {
  name: string;
  value: number;
};

type CrStatisticsPieProps = {
  data?: PieData[];
  displayMode: 'values' | 'percent' | 'none';
  verticalAlign: 'top' | 'middle' | 'bottom';
  align: 'left' | 'center' | 'right';
  height?: number;
};

export default function CrStatisticsPie({
  data,
  displayMode,
  verticalAlign,
  align,
  height
}: CrStatisticsPieProps) {
  const [colors, setColors] = useState<string[]>();

  function generateColorGradient(
    startColor: string,
    endColor: string,
    numColors: number
  ): string[] {
    const startRGB = hexToRGB(startColor);
    const endRGB = hexToRGB(endColor);

    const colorGradient = [];
    for (let i = 0; i < numColors; i++) {
      const ratio = i / (numColors - 1);
      const color = interpolateColor(startRGB, endRGB, ratio);
      colorGradient.push(rgbToHex(color[0], color[1], color[2]));
    }

    return colorGradient;
  }

  function hexToRGB(hex: string): number[] {
    hex = hex.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  }

  function interpolateColor(color1: number[], color2: number[], ratio: number): number[] {
    const r = Math.round(color1[0] + ratio * (color2[0] - color1[0]));
    const g = Math.round(color1[1] + ratio * (color2[1] - color1[1]));
    const b = Math.round(color1[2] + ratio * (color2[2] - color1[2]));
    return [r, g, b];
  }

  function rgbToHex(r: any, g: any, b: any) {
    return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
  }

  const PASTELGREEN = '#7ED17C';
  const CANTALOUPE = '#F7A677';
  const CORNFLOWER = '#95CDE8';

  useEffect(() => {
    data?.map((item: any) => {
      if (item.name === 'null') {
        item.name = 'Inconnu';
      }
    });

    if (data !== undefined) {
      if (data.length === 2) {
        setColors([CORNFLOWER, CANTALOUPE]);
      } else if (data.length === 3) {
        setColors([CORNFLOWER, PASTELGREEN, CANTALOUPE]);
      } else if (data.length > 3) {
        const isEven = data.length % 2 === 0;
        const middleValue = Math.floor(data.length / 2);
        const colorGradientStart = generateColorGradient(
          CORNFLOWER,
          PASTELGREEN,
          middleValue + (isEven ? 1 : 0)
        );
        isEven && colorGradientStart.pop();

        const colorGradientEnd = generateColorGradient(
          PASTELGREEN,
          CANTALOUPE,
          middleValue + (isEven ? 1 : 2)
        );
        colorGradientEnd.shift();

        const colorGradient = colorGradientStart.concat(colorGradientEnd);
        setColors(colorGradient);
      }
    }
  }, [data]);

  const total = data?.reduce((sum, entry) => sum + entry.value, 0);

  const CustomLegendFormatter = (value: string, entry: any) => {
    const dataItem = data?.find((item) => item.name === value);
    if (!dataItem) return value;
    let displayValue: string | number = dataItem.value;
    if (displayMode === 'percent') {
      displayValue = `${Math.round((dataItem.value / (total as number)) * 100)}%`;
    }

    return (
      <span style={{ color: entry.color }}>
        {value} ({displayValue})
      </span>
    );
  };

  return (
    <Box sx={{ m: 2 }}>
      <ResponsiveContainer width={'100%'} height={300}>
        <PieChart height={700}>
          {total && (
            <Legend
              layout="vertical"
              verticalAlign={verticalAlign}
              align={align}
              height={height}
              wrapperStyle={{
                padding: '0 2rem',
                height: '100%',
                overflow: 'auto'
              }}
              formatter={CustomLegendFormatter}
            />
          )}
          <Pie
            data={data}
            label={<CustomLabel displayMode={displayMode} />}
            outerRadius={110}
            dataKey="value"
            paddingAngle={0}
          >
            {data?.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={colors ? colors[index % colors.length] : CORNFLOWER}
                />
              );
            })}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
}
