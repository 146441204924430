import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export default function RouteRedirect({
  baseRoute,
  targetRoute
}: {
  baseRoute: string;
  targetRoute: string;
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === baseRoute) navigate(targetRoute);
  }, [navigate, baseRoute, targetRoute]);
  return <Outlet />;
}
