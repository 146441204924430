/* eslint-disable */
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin, { Draggable, EventResizeDoneArg } from '@fullcalendar/interaction';
import axios from 'axios';
import { EventDropArg } from '@fullcalendar/core';
import { DragMetaInput } from '@fullcalendar/core/internal';
import CalendarEventModal from './CalendarEventModal';
import { CrIntervention } from '../../CR/Cases/Types';
import allLocales from '@fullcalendar/core/locales-all';
import './Calendar.scss';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { fr } from 'date-fns/locale';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { endOfWeek, format, startOfWeek } from 'date-fns';
import { useNotif } from '@applications-terrains/birdz-react-library';
import { BirdzNotif } from '@applications-terrains/birdz-react-library';
import { useGetSubcontractorDetails } from '../../../hooks/datarefs';

type FormatedEvent = {
  id: number;
  scheduled_start_date?: string;
  duration_hours?: number;
  technician?: number;
};

type ResourceTechnician = {
  groups: number[];
  id: number;
  main_contact: boolean;
  subcontractor: number;
  subcontractor__name: string;
  user: number;
  user__email: string;
  user__name: string;
  user__phone: string;
};

type CalendarProps = {
  interventions?: CrIntervention[];
  nonScheduledInterventions: CrIntervention[];
  onChange: () => void;
};

type CurrentViewCalendar = 'resourceTimelineDay' | 'resourceTimelineWeek' | 'resourceTimelineMonth';

const Calendar = forwardRef<HTMLDivElement, CalendarProps>(
  (
    { interventions, nonScheduledInterventions, onChange, ...props },
    listingRef: ForwardedRef<HTMLDivElement>
  ) => {
    const scheduledInterventionsEndpoint = '/api/boe/cases/planning/';
    const calendarRef = useRef(null);
    const [scheduledInterventions, setScheduledInterventions] = useState<CrIntervention[]>([]);
    const [technicians, setTechnicians] = useState<ResourceTechnician[]>([]);
    const [modalData, setModalData] = useState<any>(null);
    const [calendarEvents, setCalendarEvents] = useState<any>();
    const [date, setDate] = useState<Date>(new Date());
    const [currentView, setCurrentView] = useState<CurrentViewCalendar>('resourceTimelineDay');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { notif, notifOptions } = useNotif();
    const { data: subContractorDetails } = useGetSubcontractorDetails();

    const [fullCalendarProps, setFullCalandarProps] = useState<any>({
      slotMinTime: '08:00:00',
      slotMaxTime: '17:00:00'
    });

    const formatTechniciansToResources = (
      technicians: ResourceTechnician[],
      interventions: CrIntervention[]
    ) => {
      return technicians.map((technician: ResourceTechnician) => {
        const technicianInterventions = interventions.filter((intervention: any) => {
          return intervention.technician === technician.user;
        });

        return {
          id: technician.user,
          title: technician.user__name,
          technician: technician,
          interventions: technicianInterventions
        };
      });
    };

    const loadTechnicians = useCallback(
      async (interventions: any[]) => {
        if (technicians.length) {
          return;
        }
        const response = await axios.get(`/api/boe/subcontractors/technicians/`);
        const newTechnicians = response.data.results;
        setTechnicians(newTechnicians);
        const newResources = formatTechniciansToResources(newTechnicians, interventions);
        setFullCalandarProps({ ...fullCalendarProps, ...{ resources: newResources } });
      },
      [fullCalendarProps, technicians]
    );

    const loadScheduledInterventions = useCallback(async () => {
      const response = await axios.get(scheduledInterventionsEndpoint);
      setScheduledInterventions(response.data);
      return response;
    }, []);

    const getNonScheduledInterventionById = useCallback(
      (id: number) => {
        return nonScheduledInterventions
          ? nonScheduledInterventions.find((intervention: any) => intervention.id === id)
          : null;
      },
      [nonScheduledInterventions]
    );

    const getInterventionTitle = (intervention: any) => {
      return intervention?.cr_case__number;
    };

    useEffect(() => {
      setIsLoading(true);
      loadScheduledInterventions().then((response: any) => {
        loadTechnicians(response.data).then(() => {
          setIsLoading(false);
        });
      });

      //eslint-disable-next-line
    }, []);

    const getApi = () => {
      const { current: calendarDom }: any = calendarRef;
      return calendarDom ? calendarDom.getApi() : null;
    };

    useEffect(() => {
      let draggable: any;

      if (listingRef) {
        if (scheduledInterventions) {
          const newCalendarEvents = scheduledInterventions.map((intervention: any) => {
            return {
              resourceId: intervention.technician,
              title: getInterventionTitle(intervention),
              intervention: intervention,
              start: intervention.scheduled_start_date,
              end: moment(intervention.scheduled_start_date)
                .add(intervention.duration_hours, 'hours')
                .format(),
              id: intervention.id,
              editable: intervention?.closed_by_technician === false
            };
          });

          if (newCalendarEvents && newCalendarEvents.length > 0) {
            setCalendarEvents(newCalendarEvents);
          }
        }

        draggable = new Draggable((listingRef as any).current, {
          itemSelector: '.fc-event',
          eventData: function (eventEl) {
            const id = eventEl?.dataset?.id ? +eventEl?.dataset?.id : null;
            const event: DragMetaInput = {
              id: null,
              title: '',
              duration: {
                hours: 1
              },
              create: true
            };
            if (id) {
              const intervention = getNonScheduledInterventionById(id);
              event.id = id;
              event.title = getInterventionTitle(intervention);
              event.intervention = intervention;
            }

            return event;
          }
        });
      }

      // a cleanup function
      return () => {
        if (draggable) {
          draggable.destroy();
        }
      };
      //eslint-disable-next-line
    }, [scheduledInterventions]);

    const getIntervention = async (interventionId: number) => {
      const response = await axios.get(
        '/api/boe/cases/cr-interventions-details/' + interventionId + '/'
      );
      return response.data;
    };

    const updateIntervention = async (interventionFields: any): Promise<any> => {
      const interventionId = interventionFields.id;
      if (!interventionId) {
        return null;
      }

      const interventionToUpdate = await getIntervention(interventionId);
      const payload: Partial<FormatedEvent> = Object.assign(
        { updated_at: interventionToUpdate.updated_at, cr_case: interventionToUpdate.cr_case },
        interventionFields
      );
      delete payload?.id;
      return axios.put('/api/boe/cases/planning/' + interventionId + '/', payload);
    };

    const showError = (axiosResponse: { response: { data: { error: string } } }) => {
      const errorMessage = ` : ${axiosResponse?.response?.data?.error || ''}`;
      notif({
        type: 'error',
        content: 'Une erreur est survenue lors de plannification' + errorMessage
      });
    };

    const showSuccess = (message: string) => {
      notif({
        type: 'success',
        content: message
      });
    };

    const CalendarDate = () => {
      let dateString = '';
      if (currentView === 'resourceTimelineDay') {
        dateString = format(date, 'EEEE dd MMMM yyyy', { locale: fr });
      } else if (currentView === 'resourceTimelineWeek') {
        dateString = `semaine du ${format(startOfWeek(date, { weekStartsOn: 1 }), 'dd MMMM yyyy', {
          locale: fr
        })} au ${format(endOfWeek(date, { weekStartsOn: 1 }), 'dd MMMM yyyy', { locale: fr })}`;
      } else {
        dateString = format(date, 'MMMM yyyy', { locale: fr });
      }

      dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1);
      return (
        <Box sx={{ textAlign: 'center' }}>
          <Typography sx={{ fontSize: '20px' }}>{dateString}</Typography>
        </Box>
      );
    };

    const ResourceContent = ({ resource }: any) => {
      const technician = resource?.resource?.extendedProps?.technician;
      let interventions = resource?.resource?.extendedProps?.interventions;
      interventions = interventions.filter((intervention: any) => {
        return (
          new Date(intervention.scheduled_start_date) >= new Date(resource.view.activeStart) &&
          new Date(intervention.scheduled_start_date) <= new Date(resource.view.activeEnd)
        );
      });

      const totalDurations = interventions?.reduce((acc: number, intervention: any) => {
        return acc + intervention.duration_hours;
      }, 0);

      return (
        <div className="technician">
          {technician?.user__name}
          <br />
          <div className="technicianChip">
            <BuildCircleIcon fontSize="small" /> {interventions.length}{' '}
            <AccessTimeIcon fontSize="small" sx={{ ml: 1 }} /> {totalDurations}h
          </div>
        </div>
      );
    };

    const EventContent = ({ event }: any) => {
      const intervention = event.event.extendedProps?.intervention;
      return (
        <div className="eventContent">
          {intervention?.device_id}
          <br />
          {intervention?.cr_case__number} ({intervention?.location?.city})
        </div>
      );
    };

    const formatEvent = (
      fullCalendarEvent: EventDropArg | EventResizeDoneArg | any
    ): FormatedEvent => {
      const event = fullCalendarEvent.event;
      let startDate = moment(event.start);
      let endDate = moment(event.end);
      try {
        if (currentView === 'resourceTimelineMonth') {
          startDate = startDate.startOf('day');
          if (subContractorDetails?.working_day_duration_hours) {
            const findIsoWeekDay = subContractorDetails.weekdays_working_hours.find(
              (workingDay) => workingDay.isoweekday === startDate.isoWeekday()
            );

            if (findIsoWeekDay) {
              let [startHours, startMinutes] = findIsoWeekDay.start_working.split(':');
              startDate.add(+startHours, 'hours').add(+startMinutes, 'minutes');
            } else {
              startDate.add(8, 'hours');
            }
          } else {
            startDate.add(8, 'hours');
          }
        }
      } catch (e) {}

      const intervention: FormatedEvent = {
        id: event?.extendedProps?.id ? +event?.extendedProps?.id : +event?.id,
        scheduled_start_date: startDate.format(),
        duration_hours: endDate.diff(startDate, 'hours', true)
      };

      if (event?.newResource && event?.newResource?.id) {
        intervention.technician = event.newResource.id;
      }

      return intervention;
    };

    const eventResize = (event: EventResizeDoneArg) => {
      const formatedEvent = formatEvent(event);

      updateIntervention(formatedEvent).then(
        () => {
          showSuccess('Intervention modifiée avec succès');
        },
        (err) => {
          showError(err);
        }
      );
    };

    const onEventDrop = (event: EventDropArg) => {
      if (event.event.startEditable === false) {
        return;
      }

      const formatedEvent = formatEvent(event);
      if (event?.newResource?.id) {
        formatedEvent.technician = +event?.newResource?.id;
      }

      updateIntervention(formatedEvent).then(
        () => {
          showSuccess('Intervention modifiée avec succès');
        },
        (err) => {
          showError(err);
        }
      );
    };

    const onDrop = async (eventDrop: any) => {
      const date = eventDrop.date;
      const resourceId = eventDrop.resource?.id;
      const id = eventDrop?.draggedEl?.dataset?.id ? +eventDrop?.draggedEl?.dataset?.id : null;

      if (id) {
        const foundIntervention = getNonScheduledInterventionById(id);
        if (foundIntervention) {
          const intervention = {
            id,
            scheduled_start_date: moment(date).format(),
            technician: +resourceId,
            duration_hours: 2
          };

          updateIntervention(intervention).then(
            () => {
              onChange();
            },
            (err) => {
              showError(err);
            }
          );
        }
      }
    };

    const onEventClick = async (event: any) => {
      const formatedEvent = formatEvent(event);
      const intervention = await getIntervention(formatedEvent.id);
      setModalData(intervention);
    };

    const getSlotWidth = () => {
      switch (currentView) {
        case 'resourceTimelineDay':
          return 50;
        case 'resourceTimelineWeek':
          return 75;
        case 'resourceTimelineMonth':
          return 300;
        default:
          return 50;
      }
    };

    const getSlotLabelFormat = () => {
      switch (currentView) {
        case 'resourceTimelineDay':
          return { hour: 'numeric' };
        case 'resourceTimelineWeek':
          return [{ day: 'numeric', weekday: 'short', month: 'short' }, { hour: 'numeric' }];
        case 'resourceTimelineMonth':
          return [{ day: 'numeric', weekday: 'short' }];
      }
    };

    return (
      <Paper variant="outlined" square sx={{ p: 2, mt: 1 }} className="calendar">
        {isLoading ? (
          <LinearProgress />
        ) : (
          <>
            {fullCalendarProps?.resources && fullCalendarProps.resources.length > 0 ? (
              <>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <Box>
                    <Autocomplete
                      options={technicians.map((technician) => {
                        return { id: technician.id, label: technician.user__name };
                      })}
                      renderInput={(params: any) => <TextField {...params} label="Techniciens" />}
                      onChange={(e: any, value: any) => {
                        const selectedTechnician = technicians.find((technician) => {
                          return technician.id === value?.id;
                        });

                        if (selectedTechnician) {
                          const newResources = formatTechniciansToResources(
                            [selectedTechnician],
                            scheduledInterventions
                          );
                          setFullCalandarProps({
                            ...fullCalendarProps,
                            ...{ resources: newResources }
                          });
                        } else {
                          const newResources = formatTechniciansToResources(
                            technicians,
                            scheduledInterventions
                          );
                          setFullCalandarProps({
                            ...fullCalendarProps,
                            ...{ resources: newResources }
                          });
                        }
                      }}
                      sx={{ width: '200px' }}
                      size="small"
                    />
                  </Box>

                  <Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          const api = getApi();
                          if (api) {
                            api.prev();
                            setDate(getApi().getDate());
                          }
                        }}
                      >
                        <KeyboardArrowLeftIcon fontSize="small" />
                      </IconButton>
                      <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                          onChange={(date: any) => {
                            getApi().gotoDate(date);
                            setDate(getApi().getDate());
                          }}
                          value={getApi()?.getDate()}
                          slots={{ textField: (params) => <TextField {...params} /> }}
                        />
                      </LocalizationProvider>
                      <IconButton
                        onClick={() => {
                          const api = getApi();
                          if (api) {
                            api.next();
                            setDate(api?.getDate());
                          }
                        }}
                      >
                        <KeyboardArrowRightIcon fontSize="small" />
                      </IconButton>
                    </Box>
                    <CalendarDate />
                  </Box>

                  <Box>
                    <Button
                      variant={currentView === 'resourceTimelineDay' ? 'contained' : 'outlined'}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        getApi().changeView('resourceTimelineDay');
                        setCurrentView('resourceTimelineDay');
                      }}
                    >
                      Jour
                    </Button>
                    <Button
                      variant={currentView === 'resourceTimelineWeek' ? 'contained' : 'outlined'}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        getApi().changeView('resourceTimelineWeek');
                        setCurrentView('resourceTimelineWeek');
                      }}
                    >
                      Semaine
                    </Button>
                    <Button
                      variant={currentView === 'resourceTimelineMonth' ? 'contained' : 'outlined'}
                      sx={{ mr: 1 }}
                      onClick={() => {
                        getApi().changeView('resourceTimelineMonth');
                        setCurrentView('resourceTimelineMonth');
                      }}
                    >
                      Mois
                    </Button>
                  </Box>
                </Stack>
                <Box style={{ height: '400px' }}>
                  <FullCalendar
                    height={'100%'}
                    ref={calendarRef}
                    locales={allLocales}
                    locale="fr"
                    plugins={[resourceTimelinePlugin, momentPlugin, interactionPlugin]}
                    initialView={currentView}
                    slotLabelFormat={getSlotLabelFormat()}
                    weekends={false}
                    mirrorSelector={'.table'}
                    droppable={true}
                    drop={onDrop}
                    editable={true}
                    eventResize={eventResize}
                    eventDrop={onEventDrop}
                    eventClick={onEventClick}
                    resourceAreaWidth="15%"
                    resourceLabelContent={(resource: any, cellEls: any) => (
                      <ResourceContent resource={resource} />
                    )}
                    events={calendarEvents}
                    eventBackgroundColor={'transparent'}
                    eventBorderColor="transparent"
                    eventContent={(event) => <EventContent event={event} />}
                    resourceAreaColumns={[{ field: 'title', headerContent: 'Technicien' }]}
                    timeZone={'local'}
                    headerToolbar={{
                      left: '',
                      center: '',
                      right: ''
                    }}
                    eventMinWidth={getSlotWidth()}
                    slotMinWidth={getSlotWidth()}
                    className={`currentView-${currentView}`}
                    {...fullCalendarProps}
                  />
                </Box>
                <CalendarEventModal
                  data={modalData}
                  onClose={() => {
                    setModalData(null);
                  }}
                />
              </>
            ) : (
              <Alert severity="info" sx={{ mt: 1 }}>
                Vous devez créer un technicien depuis l'administration pour pouvoir planifier des
                interventions
              </Alert>
            )}
          </>
        )}
        <BirdzNotif options={notifOptions} />
      </Paper>
    );
  }
);

export default Calendar;
