import React from 'react';
import { Outlet } from 'react-router-dom';
import { TourFormContextProvider } from './TourForm/context/tourFormContext';

export default function Tours() {
  return (
    <TourFormContextProvider>
      <Outlet />
    </TourFormContextProvider>
  );
}
