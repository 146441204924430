import { SearchFormValue } from '@applications-terrains/birdz-react-library';
import React, { ReactNode, createContext, useContext, useState } from 'react';

type InterventionFiltersContextType = {
  filtersDataString: string;
  setFiltersDataString: (value: string) => void;
  filtersDataValues: SearchFormValue;
  setFiltersDataValues: (values: SearchFormValue) => void;
  currentBookmark: Bookmark;
  setCurrentBookmark: (value: Bookmark) => void | null;
};

export type Bookmark = {
  name: string;
  filters: any;
  id?: number;
  updated_at?: number;
  created_at?: number;
} | null;

const InterventionFiltersContext = createContext<InterventionFiltersContextType | undefined>(
  undefined
);

export const useInterventionFiltersContext = () => {
  const context = useContext(InterventionFiltersContext);

  if (context === undefined) {
    throw new Error('useInterventionFiltersContext must be used with a InterventionFiltersContext');
  }

  return context;
};

export const InterventionsFiltersContextProvider = ({ children }: { children: ReactNode }) => {
  const [filtersDataString, setFiltersDataString] = useState<string>('');
  const [filtersDataValues, setFiltersDataValues] = useState<SearchFormValue>({
    formValue: {},
    filterValues: {}
  });
  const [currentBookmark, setCurrentBookmark] = useState<Bookmark>(null);

  const value = {
    filtersDataString,
    setFiltersDataString,
    filtersDataValues,
    setFiltersDataValues,
    currentBookmark,
    setCurrentBookmark
  };

  return (
    <InterventionFiltersContext.Provider value={value}>
      {children}
    </InterventionFiltersContext.Provider>
  );
};
