import React, { useEffect, useState } from 'react';
import {
  BirdzNotif,
  BirdzTitle,
  ListField,
  SearchField,
  SearchFormValue,
  useNotif
} from '@applications-terrains/birdz-react-library';
import { formatToSelectOptions, useGetCrContracts, useGetYears } from '../../../hooks/datarefs';
import CrStatisticsDetail from './CrStatisticsDetail';
import { PiFileCsv } from 'react-icons/pi';
import moment from 'moment';
import BTable from '../../Tools/Table/BTable';
import BSearchForm from '../../Tools/Search/BSearchForm';
import PagePaper from '../../Tools/Pages/PagePaper';
import { Box } from '@mui/material';

export default function CrPlus() {
  const endpoint = '/api/boi/cases/cr-cases/';
  const [filterString, setFilterString] = useState<string>('');
  const [searchFields, setSearchFields] = useState<SearchField[]>([]);
  const [filtersDataValues, setFiltersDataValues] = useState<SearchFormValue>({
    formValue: {},
    filterValues: {}
  });
  const { notif, notifOptions } = useNotif();

  const { data: contracts } = useGetCrContracts();
  const { data: years } = useGetYears();

  useEffect(() => {
    const searchFields: SearchField[] = [
      {
        name: 'created_at_after',
        label: 'Entre le ',
        type: 'datepicker'
      },
      {
        name: 'created_at_before',
        label: 'Et le',
        type: 'datepicker'
      },
      {
        name: 'pdc_pdr',
        label: 'Numéro PDC',
        type: 'text'
      },
      {
        name: 'device_id',
        label: 'Numéro CR',
        type: 'text'
      },
      {
        name: 'contract_code_label',
        label: 'Contrat',
        type: 'select',
        options: {
          values: contracts
            ? contracts?.map((contract: any) => {
                return {
                  value: contract,
                  label: contract
                };
              })
            : []
        }
      },
      {
        name: 'city_id',
        label: 'Commune / CP',
        type: 'autocomplete',
        options: {
          identifier: 'id',
          source: '/api/boi/params/cities/',
          label: 'city_zipcode',
          searchIsMulti: false,
          formatResponse: (response: any) => {
            return {
              label: `${response.label} - ${response.zip_code}`,
              value: response.id,
              raw: response
            };
          }
        }
      },
      {
        name: 'subcontractor',
        label: 'Sous-traitant',
        options: {
          identifier: 'id',
          label: 'name',
          source: '/api/boi/subcontractors/subcontractors/?is_active=true&'
        }
      },
      {
        name: 'with_intervention',
        label: 'Avec interventions',
        type: 'select',
        options: {
          values: [
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' }
          ]
        }
      },
      {
        name: 'year_id',
        label: 'Année',
        type: 'select',
        options: {
          values: formatToSelectOptions(years) || []
        }
      }
    ];
    setSearchFields(searchFields);
  }, [contracts, years]);

  const listFields: ListField[] = [
    {
      name: 'number',
      label: "Numéro d'incident"
    },
    {
      name: 'type__name',
      label: 'Type',
      transform: (value: string | null) => {
        return value || '-';
      }
    },
    {
      name: 'pdc_pdr',
      label: 'PDC/PDR',
      transform: (value: string | null) => {
        return value || '-';
      }
    },
    {
      name: 'device_id',
      label: 'Adresse radio',
      transform: (value: string | null) => {
        return value || '-';
      }
    },
    {
      name: 'contract',
      label: 'Contrat',
      transform: (_, item: { contract_code: string | null; contract_label: string | null }) => {
        const { contract_code, contract_label } = item;
        return (
          `${contract_code || ''}${contract_code && contract_label ? ' - ' : ''}${contract_label || ''}` ||
          '-'
        );
      }
    },
    {
      name: 'subcontractor',
      label: 'Sous-traitant',
      transform: (subcontractor: string | null) => {
        return subcontractor || '-';
      }
    },
    {
      name: 'intervention_created_at',
      label: "Création de l'intervention (support)",
      transform: (value: any) => {
        return (value && moment(value).format('DD/MM/YYYY à HH:mm')) || '-';
      }
    },
    {
      name: 'end_intervention_date',
      label: "Clôture de l'intervention (technicien)",
      transform: (value: any) => {
        return (value && moment(value).format('DD/MM/YYYY à HH:mm')) || '-';
      }
    }
  ];

  return (
    <Box className="h-100 d-flex flex-column">
      <PagePaper bordered title={<BirdzTitle className="m-0">Rapport maintenance CR+</BirdzTitle>}>
        <BSearchForm
          values={filtersDataValues}
          fields={searchFields}
          setSearchString={(filterString) => {
            setFilterString(filterString);
          }}
          setSearchValues={setFiltersDataValues}
        />

        <BTable
          endpoint={endpoint + filterString.replace('&', '?')}
          model={`cr-statistics`}
          fields={listFields}
          order={'-updated_at'}
          exportButtons={[
            {
              name: 'Exporter en csv',
              fileName: 'statistiques_cr_plus',
              id: 'cr-statistics-export-button',
              type: 'csv',
              icon: <PiFileCsv />,
              requestEndpoint:
                '/api/boi/cases/statistics-csv-export/?ordering=-updated_at' + filterString,
              keepQueryParams: false,

              onSuccess: () => {
                notif({
                  type: 'success',
                  content: 'Export réussi'
                });
              }
            }
          ]}
        />

        <CrStatisticsDetail filter={filterString} />

        <BirdzNotif options={notifOptions} />
      </PagePaper>
    </Box>
  );
}
