import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GroupIcon from '@mui/icons-material/Group';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Backdrop, Box, Button } from '@mui/material';
import { SupportId } from './SupportsList';

const SupportSourceSelector = ({
  setNewSupportSource,
  newSupportSource,
  setSupportId
}: {
  setNewSupportSource: (value: 'users' | 'new') => void;
  newSupportSource: 'users' | 'new';
  setSupportId: (value: SupportId) => void;
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Button
      variant="contained"
      onClick={() => {
        setSupportId('add');
      }}
      size="small"
      sx={{ mt: 4 }}
    >
      <Box className="d-flex" sx={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}>
        <AddCircleIcon sx={{ mr: 1 }} />
        <Box>Ajouter un </Box>
        <Box
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setExpanded(!expanded);
          }}
          sx={{
            ml: 0.5,
            pl: 1,
            pr: 0.5,
            backgroundColor: 'rgba(255, 255, 255, 0.35)',
            borderRadius: '14px',
            height: 24
          }}
          className="d-flex align-items-center"
        >
          {newSupportSource === 'users' ? 'utilisateur' : 'nouveau gestionnaire'}
          <ExpandMoreIcon
            sx={{
              ml: 0.5,
              transition: 'transform 0.3s ease', // Animation pour la rotation
              transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)' // Rotation de l'icône
            }}
          />
        </Box>
        {
          <Backdrop
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              cursor: 'default'
            }}
            open={expanded}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setExpanded(false);
            }}
          />
        }
        {
          <Box
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setNewSupportSource(newSupportSource === 'new' ? 'users' : 'new');
              setExpanded(false);
              setSupportId('add');
            }}
            sx={{
              px: 1,
              backgroundColor: '#253053',
              borderRadius: '14px',
              display: 'flex',
              opacity: expanded ? 1 : 0,
              pointerEvents: expanded ? 'auto' : 'none',
              transform: expanded ? 'translateY(0)' : 'translateY(30px)', // Déplacer l'élément verticalement
              position: 'absolute',
              transition: 'transform 0.3s ease, opacity 0.3s ease', // Animation pour la translation et l'opacité
              top: -30, // L'élément restera à `top: 0` mais se déplacera en Y
              right: 0,
              height: 24
            }}
            className="d-flex align-items-center"
          >
            {newSupportSource === 'new' ? 'utilisateur' : 'nouveau gestionnaire'}

            {newSupportSource === 'new' ? (
              <GroupIcon sx={{ ml: 0.5 }} fontSize="small" />
            ) : (
              <FiberNewIcon sx={{ ml: 0.5 }} fontSize="small" />
            )}
          </Box>
        }
      </Box>
    </Button>
  );
};

export default SupportSourceSelector;
