import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Chip,
  Grid,
  LinearProgress,
  Stack,
  Typography
} from '@mui/material';
import CrStatisticsPie from './CrStatisticsPie';
import CrStatisticsMap from './CrStatisticsMap';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PagePaper from '../../Tools/Pages/PagePaper';

type CrStatisticsDetailProps = {
  filter: string;
};

type Statistic = {
  odm_in_progress: number;
  all_odm: number;
  odm_replaced: number;
  odm_waiting_csm: number;
  delay_inter: number;
  delay_breakdown: number;
  exclusive_modules: number;
  code_contracts: Record<string, number>;
  device_power_supply_types: Record<string, number>;
  device_patrimony_types: Record<string, number>;
  coordinates: Coordinates[];
  failure_codes: Record<string, number>;
  types: Record<string, number>;
};

type Coordinates = {
  long: number;
  lat: number;
};

export default function CrStatisticsDetail({ filter }: CrStatisticsDetailProps) {
  const [statistics, setStatistics] = useState<Statistic>();
  const [isLoading, setLoading] = useState<boolean>(false);

  const formatData = (data: Record<string, number> | undefined) => {
    if (data) {
      return Object.keys(data).map((property: string) => {
        return { name: property, value: data[property] };
      });
    } else {
      return [];
    }
  };

  useEffect(() => {
    const filterString = filter.substring(1);

    const fetchStatistics = async () => {
      setLoading(true);
      await axios.get(`/api/boi/cases/cr-statistics/?` + filterString).then(
        (response) => {
          setLoading(false);
          const data = response.data;
          setStatistics(data);
        },
        () => {
          setStatistics(undefined);
          setLoading(false);
        }
      );
    };

    fetchStatistics();
  }, [filter]);

  const chips = [
    { label: 'ODM en cours', value: statistics?.odm_in_progress },
    { label: "Nombre total d'ODM", value: statistics?.all_odm },
    { label: 'ODM avec remplacement de CR', value: statistics?.odm_replaced },
    { label: 'En attente du Front Office ', value: statistics?.odm_waiting_csm },
    { label: 'Délai moyen des interventions', value: Number(statistics?.delay_inter?.toFixed(2)) },
    { label: 'Délai moyen des pannes', value: Number(statistics?.delay_breakdown?.toFixed(2)) },
    { label: 'Module exclusifs impactés', value: statistics?.exclusive_modules }
  ];

  const charts: {
    label: string;
    data: {
      name: string;
      value: number;
    }[];
    displayMode: 'values' | 'percent';
  }[] = [
    { label: 'Contrats', data: formatData(statistics?.code_contracts), displayMode: 'values' },
    { label: 'Codes panne', data: formatData(statistics?.failure_codes), displayMode: 'percent' },
    { label: 'Types', data: formatData(statistics?.types), displayMode: 'values' },
    {
      label: "Types d'alimentation",
      data: formatData(statistics?.device_power_supply_types),
      displayMode: 'percent'
    },
    {
      label: 'Types de devices',
      data: formatData(statistics?.device_patrimony_types),
      displayMode: 'values'
    }
  ];

  return isLoading ? (
    <LinearProgress sx={{ mt: 2 }} />
  ) : statistics ? (
    <>
      <Accordion
        defaultExpanded={true}
        sx={{
          mt: 5,
          boxShadow: 'none',
          '::before': {
            display: 'none'
          }
        }}
        disableGutters={true}
      >
        <AccordionSummary
          sx={{ borderBottom: '1px solid', borderColor: 'divider' }}
          expandIcon={<ExpandMore />}
        >
          <h3>Statistiques</h3>
        </AccordionSummary>
        <AccordionDetails>
          <Stack gap={1} direction="row" justifyContent={'center'} sx={{ mt: 2 }} flexWrap={'wrap'}>
            {chips.map((chip, index) => (
              <Chip
                key={'statistic-chip-' + index}
                label={
                  <>
                    {chip.label} : <strong>{chip.value}</strong>
                  </>
                }
                variant="outlined"
              />
            ))}
          </Stack>
          <Grid container sx={{ mt: 2 }} spacing={1}>
            {charts.map((chart, index) => (
              <Grid key={'statistics-chart-' + index} item xs={6} sx={{ textAlign: 'center' }}>
                <PagePaper
                  bordered
                  title={
                    <Typography
                      variant="overline"
                      sx={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      {chart.label}
                    </Typography>
                  }
                >
                  <CrStatisticsPie
                    data={chart.data}
                    displayMode={chart.displayMode}
                    verticalAlign="middle"
                    align="right"
                  />
                </PagePaper>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
        defaultExpanded={true}
        sx={{
          boxShadow: 'none',
          mt: 5,
          '::before': {
            display: 'none'
          }
        }}
        disableGutters={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          sx={{ borderBottom: '1px solid', borderColor: 'divider' }}
        >
          <h3>Cartographie</h3>
        </AccordionSummary>
        <AccordionDetails>
          <CrStatisticsMap markers={statistics.coordinates} />
        </AccordionDetails>
      </Accordion>
    </>
  ) : (
    <>
      <Grid item sx={{ mt: 2 }}></Grid>
      <Alert severity="info" sx={{ mt: 2 }}>
        Aucune statistique pour les filtres sélectionnés
      </Alert>
    </>
  );
}
