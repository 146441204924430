import { Client, ModuleFamilyType } from './ClientsForm';

export const getFormattedContacts = (client: Client) => ({
  inter_issue_contacts: client.inter_issue_contacts
    ? client.inter_issue_contacts?.split('\n')
    : null,
  export_issue_contacts: client.export_issue_contacts
    ? client.export_issue_contacts?.split('\n')
    : null,
  export_success_contacts: client.export_success_contacts
    ? client.export_success_contacts?.split('\n')
    : null
});

export const formatModuleFamiliesForDB = (
  module_families: number[],
  moduleFamilies: ModuleFamilyType[]
) => {
  return (
    (module_families
      ?.map((moduleFamilyId: number) => {
        const foundModuleFamily = moduleFamilies?.find(
          (moduleFamily) => moduleFamily.Id === moduleFamilyId
        );
        return foundModuleFamily?.Name;
      })
      .filter((familyName: string | undefined) => familyName) as string[]) || []
  );
};

export const formatModuleFamiliesFromDB = (
  module_families: string[],
  moduleFamilies: ModuleFamilyType[]
) => {
  return (
    (module_families
      ?.map((moduleFamilyName: string) => {
        const foundModuleFamily = moduleFamilies?.find(
          (moduleFamily: { Id: number; Name: string }) => moduleFamily.Name === moduleFamilyName
        );
        return foundModuleFamily?.Id;
      })
      .filter((familyId: number | undefined) => familyId) as number[]) || []
  );
};
