import { Box, Paper } from '@mui/material';
import React, { PropsWithChildren, ReactNode } from 'react';
import './pages.scss';

const PagePaper = (
  props: PropsWithChildren & {
    title: ReactNode;
    className?: string;
    bordered?: boolean;
    blockScroll?: boolean;
  }
) => {
  let sx: { [key: string]: string | number } = {
    boxShadow: 'none',
    pt: 2,
    minHeight: '100%',
    overflow: 'hidden',
    boxSizing: 'border-box'
  };

  if (props.bordered)
    sx = {
      ...sx,
      border: '1px solid',
      borderColor: 'divider',
      p: 2
    };
  return (
    <Paper sx={sx} className={`d-flex flex-column page-paper ${props.className}`}>
      <Box
        sx={{
          pb: 1,
          mb: 2,
          borderBottom: '1px solid',
          borderColor: 'divider',
          boxSizing: 'border-box'
        }}
      >
        {props.title}
      </Box>
      <Box
        sx={{
          overflow: props.blockScroll ? 'hidden' : 'scroll',
          boxSizing: 'border-box'
        }}
        className="d-flex flex-column flex-grow-1"
      >
        {props.children}
      </Box>
    </Paper>
  );
};

export default PagePaper;
