import React, { PropsWithChildren, useEffect, useState } from 'react';
import { SearchFormValue } from '@applications-terrains/birdz-react-library';
import { Box, Button, IconButton, Accordion, AccordionDetails } from '@mui/material';
import { Formik, Form } from 'formik';
import './b-search-form.scss';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { getFilterString, removeFalsyValues } from '../../../utils';
import BSearchField from './Fields/BSearchField';
import { isArray } from 'lodash';
import { FieldType } from './types';

const FormBox = (props: PropsWithChildren & { columns?: number }) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${props.columns || 3}, 1fr)`,
        gridGap: 16
      }}
    >
      {props.children}
    </Box>
  );
};

const BSearchForm = ({
  fields,
  values,
  setSearchString,
  setSearchValues,
  onReinitialize,
  columns
}: {
  fields: FieldType['field'][];
  values: SearchFormValue;
  setSearchString: (search: string) => void;
  setSearchValues: (values: SearchFormValue) => void;
  onReinitialize?: () => void;
  columns?: number;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [hasSeeMoreFields, setHasSeeMoreFields] = useState(false);

  // determine if form should be expanded from mount
  useEffect(() => {
    const seeMoreFields = fields.filter((field) => field.seeMore);
    setHasSeeMoreFields(seeMoreFields.length > 0);
    const fieldsWithValueInForm: string[] = [];
    seeMoreFields.forEach((field) => {
      if (field.name in values.formValue) {
        const value = values.formValue[field.name];
        if ((isArray(value) && value.length > 0) || (!isArray(value) && value)) {
          fieldsWithValueInForm.push(field.name);
        }
      }
    });
    setExpanded(fieldsWithValueInForm.length > 0);
  }, [fields, values]);

  // Map initial values from the context's formValue
  const initialValues = {
    ...fields.reduce(
      (acc, field) => {
        acc[field.name] = values.formValue[field.name] ?? (field.multiple === true ? [] : '');
        return acc;
      },
      {} as Record<string, any>
    )
  };

  const isDisabled = (
    field: FieldType['field'],
    values: {
      [x: string]: any;
    }
  ) => {
    if (field.queryParamsSourceField?.key) {
      return (
        !(field.queryParamsSourceField?.key in values) ||
        (isArray(values[field.queryParamsSourceField?.key]) &&
          values[field.queryParamsSourceField?.key].length === 0)
      );
    } else return false;
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={(values) => {
        setSearchString(getFilterString(removeFalsyValues(values)));
        setSearchValues({
          formValue: values,
          filterValues: values
        });
      }}
    >
      {({ values, setFieldValue, handleReset }) => {
        return (
          <Form>
            <Box
              className="form-container"
              sx={{
                borderBottom: '1px solid',
                borderColor: 'divider',
                pt: 3,
                mb: 2
              }}
            >
              <FormBox columns={columns}>
                {fields
                  .filter((el) => !el.seeMore)
                  .map((field, index) => (
                    <BSearchField
                      key={field.name || (field?.type || 'field') + index}
                      field={field}
                      values={values}
                      setFieldValue={setFieldValue}
                      disabled={isDisabled(field, values)}
                    />
                  ))}
              </FormBox>
              <Accordion
                elevation={0}
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
                sx={{
                  '::before': {
                    display: 'none'
                  }
                }}
              >
                <Box></Box>
                <AccordionDetails sx={{ p: 0 }}>
                  <FormBox columns={columns}>
                    {fields
                      .filter((el) => el.seeMore)
                      .map((field, index) => (
                        <BSearchField
                          key={field.name || (field?.type || 'field') + index}
                          field={field}
                          values={values}
                          setFieldValue={setFieldValue}
                          disabled={isDisabled(field, values)}
                        />
                      ))}
                  </FormBox>
                </AccordionDetails>
              </Accordion>
              <Box
                className="buttons-container w-100 d-flex justify-content-between align-items-center"
                sx={{
                  my: 2
                }}
              >
                {hasSeeMoreFields ? (
                  <IconButton
                    title={`${expanded ? 'moins' : 'plus'} de filtres`}
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? <RemoveCircleIcon /> : <AddCircleIcon />}
                  </IconButton>
                ) : (
                  <Box></Box>
                )}
                <Box className="d-flex" sx={{ gap: 1 }}>
                  <Button
                    size="small"
                    onClick={() => {
                      handleReset();
                      setSearchString('');
                      setSearchValues({
                        formValue: {},
                        filterValues: {}
                      });
                      onReinitialize && onReinitialize();
                    }}
                    variant="outlined"
                  >
                    Reinitialiser
                  </Button>
                  <Button type="submit" variant="contained" size="small">
                    Rechercher
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BSearchForm;
