import React, { useEffect, useState } from 'react';
import { authService } from '../..';
import { SvgIconComponent } from '@mui/icons-material';

import DashboardIcon from '@mui/icons-material/Dashboard';
import RuleIcon from '@mui/icons-material/Rule';
import CallIcon from '@mui/icons-material/Call';
import BuildIcon from '@mui/icons-material/Build';
import FolderIcon from '@mui/icons-material/Folder';
import MapIcon from '@mui/icons-material/Map';
import InfoIcon from '@mui/icons-material/Info';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MediationIcon from '@mui/icons-material/Mediation';
import HistoryIcon from '@mui/icons-material/History';
import StoreIcon from '@mui/icons-material/Store';
import ArticleIcon from '@mui/icons-material/Article';
import WarningIcon from '@mui/icons-material/Warning';
import DescriptionIcon from '@mui/icons-material/Description';
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import WorkIcon from '@mui/icons-material/Work';
import ShareIcon from '@mui/icons-material/Share';
import InventoryIcon from '@mui/icons-material/Inventory';
import AppsIcon from '@mui/icons-material/Apps';
import TuneIcon from '@mui/icons-material/Tune';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import BookIcon from '@mui/icons-material/Book';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import RoundaboutRightIcon from '@mui/icons-material/RoundaboutRight';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

export type SidebarItemType = {
  key: string;
  title: string;
  icon: SvgIconComponent | null;
  children?: SidebarItemType[];
  activeRoutes?: string[];
};

const useGetSidebarItems = () => {
  const [sidebarItems, setSidebarItems] = useState<SidebarItemType[]>([]);

  useEffect(() => {
    if (authService) {
      const items: SidebarItemType[] = [
        {
          key: authService?.BOIorBOE('/boi', '/boe'),
          title: 'Support',
          icon: <CorporateFareIcon />,
          children: [
            authService?.canAccess('CALLS_MENU') && {
              key: '/calls',
              title: 'Appels',
              icon: <CallIcon />,
              children: [
                {
                  key: '/new',
                  title: 'Réception',
                  icon: null
                },
                authService?.canAccess('CALLS_MENU_PAGE') && {
                  key: '/listing',
                  title: 'Listing',
                  icon: null
                }
              ]
            },
            authService?.canAccess('CR_MENU') &&
              authService?.canAccessBOI() && {
                key: '/cr',
                title: 'CR',
                icon: <BuildIcon />,
                children: [
                  authService?.canAccess('CR_MENU_DIAG') && {
                    key: '/diagnostics',
                    title: 'Diagnostics',
                    icon: null
                  },
                  authService?.canAccess('CR_MENU_DOSSIER') && {
                    key: '/cases',
                    title: 'Dossiers',
                    icon: null
                  },
                  authService?.canAccess('CR_MENU_INTER') && {
                    key: '/interventions',
                    title: 'Interventions',
                    icon: null
                  },
                  authService?.canAccess('CR_MENU_TAB_CRP') && {
                    key: '/statistics/cr+',
                    title: 'Tableau de bord CR+',
                    icon: null
                  }
                ]
              },
            authService?.canAccess('CR_MENU') &&
              authService?.canAccessBOE() && {
                key: '/cr',
                title: 'CR',
                icon: <BuildIcon />,
                children: [
                  {
                    key: '/interventions',
                    title: 'Interventions',
                    icon: null
                  },
                  {
                    key: '/planning',
                    title: 'Planning',
                    icon: null
                  }
                ]
              },
            authService?.canAccess('MR_MENU') &&
              authService?.canAccessBOI() && {
                key: '/mr',
                title: 'MR',
                icon: <ContentCopyIcon />,
                children: [
                  authService?.canAccess('MR_MENU_DOSSIER') && {
                    key: '/diagnostics',
                    title: 'Diagnostics',
                    icon: null
                  },
                  authService?.canAccess('MR_MENU_DOSSIER') && {
                    key: '/cases',
                    title: 'Dossiers',
                    icon: null
                  }
                ]
              },
            authService?.canAccess('GED_MENU') && {
              key: authService?.BOIorBOE('/DMS', '/DMS'),
              title: 'GED',
              icon: <FolderIcon />,
              activeRoutes: ['boi/DMS', 'boe/DMS']
            },
            authService?.canAccess('INFO_CMP') && {
              key: authService?.BOIorBOE('/informations', '/informations'),
              title: 'Informations',
              icon: <InfoIcon />
            },
            authService?.canAccess('RELEVE_MENU') && {
              key: authService?.BOIorBOE('/monthly-statement', '/monthly-statement'),
              title: 'Relevés',
              icon: <HourglassBottomIcon />
            },
            authService?.canAccess('ADMIN_MENU') && {
              key: '/administration',
              title: 'Administration',
              icon: <SettingsIcon />,
              children: [
                authService?.canAccess('ADMIN_MENU_USER') && {
                  key: '/users',
                  title: 'Utilisateurs',
                  icon: null
                },
                authService?.canAccess('ADMIN_MENU_GROUP') && {
                  key: '/groups',
                  title: 'Groupes',
                  icon: null
                },
                authService?.canAccess('ADMIN_MENU_SECTION') && {
                  key: '/sections',
                  title: 'Sections',
                  icon: null
                },
                authService?.canAccess('ADMIN_MENU_ST') && {
                  key: authService?.BOIorBOE('/subcontractors', '/subcontractors'),
                  title: 'Sous-traitants',
                  icon: null
                },
                authService?.canAccess('ADMIN_LOG') && {
                  key: '/logs',
                  title: 'Logs',
                  icon: null
                },
                authService?.canAccess('ADMIN_MESSAGE') && {
                  key: '/messages',
                  title: 'Messages',
                  icon: null
                },
                authService?.isAdmin() && {
                  key: '/tables',
                  title: 'Tables techniques',
                  icon: null
                },
                authService?.isAdmin() && {
                  key: '/contracts',
                  title: 'Contrats',
                  icon: null
                }
              ]
            }
          ]
        },
        authService?.canAccess('BCONNECT_MENU') && {
          key: '/bconnect',
          title: 'B-Connect',
          icon: <MapIcon />,
          children: [
            {
              key: '/homepage',
              title: 'Accueil',
              icon: null
            },
            {
              key: '/users',
              title: 'Utilisateurs',
              icon: null
            },
            {
              key: '/dashboard',
              title: 'Dashboard',
              icon: null,
              children: [
                {
                  key: '/overview',
                  title: "Vue d'ensemble",
                  icon: null
                },
                {
                  key: '/licences',
                  title: 'Licences',
                  icon: null
                },
                {
                  key: '/notations',
                  title: 'Notations',
                  icon: null
                }
              ]
            },
            {
              key: '/interventions',
              title: 'Suivi',
              icon: <ShowChartIcon />,
              children: [
                {
                  key: '/dashboard',
                  title: 'Tableau de bord',
                  icon: <DashboardIcon />
                },
                {
                  key: '/pending',
                  title: 'Interventions',
                  icon: <PendingActionsIcon />
                }
              ]
            },
            authService?.canAccess('EXTRACTOR_MENU') && {
              key: '/extractor',
              title: 'Extract',
              icon: <ShareIcon />,
              children: [
                {
                  key: '/interventions',
                  title: 'Inters terminées',
                  icon: <RuleIcon />
                },
                {
                  key: '/jobs',
                  title: 'Jobs',
                  icon: <WorkIcon />
                },
                {
                  key: '/executions',
                  title: 'Historique des exports',
                  icon: <HistoryIcon />
                },
                authService?.canAccess('EXTRACTOR_MENU') && {
                  key: '/admin',
                  title: 'Administration',
                  icon: <MediationIcon />,
                  children: [
                    {
                      key: '/clients',
                      title: 'Clients',
                      icon: <StoreIcon />
                    },
                    {
                      key: '/anomaly-profiles',
                      title: 'Profils anomalies',
                      icon: <WarningIcon />
                    },
                    {
                      key: '/erps',
                      title: 'ERPs',
                      icon: <ArticleIcon />
                    },
                    {
                      key: '/export-formats',
                      title: "Formats d'export",
                      icon: <DescriptionIcon />
                    },
                    {
                      key: '/correspondances',
                      title: 'Correspondances',
                      icon: <JoinInnerIcon />
                    }
                  ]
                }
              ]
            }
          ]
        },
        authService?.canAccess('BSTOCK_MENU') && {
          key: '/bstock',
          title: 'B-Stock',
          icon: <InventoryIcon />,
          children: [
            {
              key: '/sessions',
              title: 'Sessions',
              icon: <AppsIcon />
            },
            {
              key: '/configurations',
              title: 'Configurations',
              icon: <TuneIcon />
            }
          ]
        },
        authService?.canAccess('BREADER_MENU') && {
          key: '/breader',
          title: 'B-Reader',
          icon: <BookIcon />,
          children: [
            {
              key: '/exports',
              title: 'Exports',
              icon: <FileUploadIcon />
            },
            {
              key: '/tours',
              title: 'Tournées',
              icon: <RoundaboutRightIcon />
            },
            {
              key: '/admin',
              title: 'Administration',
              icon: <MediationIcon />,
              children: [
                {
                  key: '/clients',
                  title: 'Clients',
                  icon: <PeopleAltIcon />
                }
              ]
            }
          ]
        },
        authService?.canAccess('BOE_BREADER') && {
          key: '/breader',
          title: 'B-Reader',
          icon: <BookIcon />,
          children: [
            {
              key: '/readers',
              title: 'Releveurs',
              icon: <ChromeReaderModeIcon />
            },
            {
              key: '/tours',
              title: 'Tournées',
              icon: <RoundaboutRightIcon />
            }
          ]
        }
      ];

      setSidebarItems(items);
    }
  }, [authService]);

  return sidebarItems;
};

export default useGetSidebarItems;
