import React, { useState } from 'react';
import BSearchForm from '../../../../../Tools/Search/BSearchForm';
import { Typography } from '@mui/material';
import { SearchFormValue } from '@applications-terrains/birdz-react-library';
import PagePaper from '../../../../../Tools/Pages/PagePaper';
import { useFetchDataRefsContext } from '../../../../../../contexts/FetchDataRefsContext';
import { deviceStatuses } from '../TourMap/TourMapUtils';

const TourDetailsFilters = () => {
  const { dataRefs, setDataRefs } = useFetchDataRefsContext();
  const [filtersDataValues, setFiltersDataValues] = useState<SearchFormValue>({
    formValue: {},
    filterValues: {}
  });
  const searchFields = [
    {
      name: 'PDS',
      label: 'PDS'
    },
    {
      name: 'COMPTEUR',
      label: 'Compteur'
    },
    {
      name: 'device_id',
      label: 'Module'
    },
    {
      name: 'rue',
      label: 'Rue'
    },
    {
      name: 'reading_state',
      label: 'Statut',
      options: {
        values: Object.entries(deviceStatuses).map(([key, value]) => ({ label: value, value: key }))
      }
    }
  ];

  const handleSetSearchValues = (values: SearchFormValue) => {
    setFiltersDataValues(values);
    if (Object.keys(values.formValue).length > 0) {
      const filteredResults = dataRefs['tour_devices']?.results.filter((device: any) => {
        return searchFields.every(({ name }) => {
          let filterValue = values.formValue[name]?.toString().trim();

          if (!filterValue) return true; // Si le filtre est vide, on l'ignore.

          if (name === 'rue') {
            // Concaténation des champs spécifiques pour la recherche "rue".
            const addressString =
              `${device.data.NUM_RUE_ABO ?? ''} ${device.data.ATTR_NUM_RUE_ABO ?? ''} ${device.data.RUE_ABO ?? ''} ${device.data.COMMUNE_ABO ?? ''}`.trim();
            return addressString.toLowerCase().includes(filterValue.toLowerCase());
          }

          let deviceValue = device.data[name]?.toString().trim();

          if (name === 'device_id' || name === 'reading_state') {
            deviceValue = device[name]?.toString().trim();
            if (name === 'reading_state')
              filterValue = values.formValue[name]?.value.toString().trim();
          }

          return deviceValue?.toLowerCase().includes(filterValue.toLowerCase());
        });
      });

      setDataRefs({
        ...dataRefs,
        tour_devices_filtered:
          filteredResults !== undefined &&
          filteredResults.length !== dataRefs['tour_devices']?.results.length
            ? {
                ...dataRefs.tour_devices,
                count: filteredResults.length,
                results: filteredResults
              }
            : null
      });
    } else {
      setDataRefs({
        ...dataRefs,
        tour_devices_filtered: null
      });
    }
  };

  return (
    <PagePaper
      title={<Typography variant="overline">Filter les équipements</Typography>}
      className="searchForm"
    >
      <BSearchForm
        columns={3}
        values={filtersDataValues}
        fields={searchFields}
        setSearchString={() => {
          // setFiltersDataString(filterString);
        }}
        setSearchValues={handleSetSearchValues}
      />
    </PagePaper>
  );
};

export default TourDetailsFilters;
