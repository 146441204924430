import React, { useCallback, useEffect, useState, JSX } from 'react';
import {
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  MenuItem,
  IconButton,
  TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFormikContext } from 'formik';
import { StyledTableHeaderRow } from '../../../styles/Table.styled';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import axios from 'axios';
import {
  BirdzDialog,
  showFriendlyErrorsHTML,
  useDialog
} from '@applications-terrains/birdz-react-library';
import UploadIcon from '@mui/icons-material/Upload';
import { InterventionPricingsModal } from './InterventionPricingsModal';
import { Pricing } from '../../../types';
import { BirdzNotif } from '@applications-terrains/birdz-react-library';
import { useNotif } from '@applications-terrains/birdz-react-library';
import { authService } from '../../../';

type PricingTypes = 'additional' | 'applicable' | 'quotation';
type PricingRow = {
  type: PricingTypes;
  id: number;
  label: string | JSX.Element;
  max_usage_limit: null | number;
};

type PdfFormValues = {
  product_subcontractor_name: string;
  price: number;
  file: any;
};

type InterventionPricingsProps = {
  readOnly?: boolean;
};

export default function InterventionPricings({ readOnly = false }: InterventionPricingsProps) {
  const { values }: any = useFormikContext();
  const [newPricing, setNewPricing] = useState<string>('');
  const [availablePricings, setAvailablePricings] = useState<PricingRow[]>();
  const [pricings, setPricings] = useState<PricingRow[]>();
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const [showModalUploadPdf, setShowModalUploadPdf] = useState<boolean>(false);
  const { notif, notifOptions } = useNotif();

  const baseInterventionEndpoint = authService.getEndpoint(
    `/api/boi/cases/technician-feedback/${values.id}/`,
    `/api/boe/cases/cr-interventions-details/${values.id}/`
  );

  const formatPricing = (type: PricingTypes, pricing: Pricing[]): PricingRow[] => {
    return pricing && pricing.length > 0
      ? pricing.map((price) => {
          const pricingRow: PricingRow = {
            type: type,
            id: price.id,
            label: price.product_subcontractor_name,
            max_usage_limit: price.max_usage_limit
          };
          return pricingRow;
        })
      : [];
  };

  const fetchPricing = useCallback(() => {
    axios.get(baseInterventionEndpoint).then((response) => {
      const additionalPricings = formatPricing('additional', response.data.additional_pricing);
      const applicablePricings = formatPricing('applicable', response.data.applicable_pricing);
      const additionalFees = response.data?.additional_fees.map((additionalFee: any) => {
        return {
          type: 'quotation',
          id: additionalFee.id,
          label: (
            <>
              {additionalFee.product_subcontractor_name} <PictureAsPdfIcon sx={{ fontSize: 18 }} />{' '}
              <a href={additionalFee.signed_url} target="_blank" rel="noreferrer">
                (télécharger)
              </a>
            </>
          )
        };
      });
      setPricings(additionalPricings.concat(applicablePricings).concat(additionalFees));
    });
  }, [values.id]);

  useEffect(() => {
    if (values.subcontractor) {
      // Get existing pricings
      fetchPricing();

      // Get avialable pricings
      const endpointSubcontractor = authService.getEndpoint(
        `/api/boi/subcontractors/subcontractors/${values.subcontractor}/`,
        `/api/boe/subcontractors/subcontractors/details/`
      );
      const interventionYear = new Date(values.created_at * 1000).getFullYear().toString();

      axios.get(endpointSubcontractor).then((response) => {
        const subcontractorDetails = response.data;
        let availablePricings: PricingRow[] = [];
        if (
          subcontractorDetails.additional_pricings &&
          subcontractorDetails.additional_pricings.length
        ) {
          availablePricings = formatPricing(
            'additional',
            subcontractorDetails.additional_pricings.filter((pricing: Pricing) => {
              return pricing.year__label === interventionYear;
            })
          );
        }
        if (
          subcontractorDetails.applicable_pricings &&
          subcontractorDetails.applicable_pricings.length
        ) {
          availablePricings = availablePricings.concat(
            formatPricing(
              'applicable',
              subcontractorDetails.applicable_pricings.filter((pricing: Pricing) => {
                return pricing.year__label === interventionYear;
              })
            )
          );
        }
        setAvailablePricings(availablePricings);
      });
    }
  }, [values.subcontractor]);

  const addPricing = () => {
    const [type, id] = newPricing.split('::');

    let addRequest;
    if (type === 'additional') {
      addRequest = axios.post(`${baseInterventionEndpoint}add-additional-pricing/`, {
        pricing_id: +id
      });
    } else if (type === 'applicable') {
      addRequest = axios.post(`${baseInterventionEndpoint}add-applicable-pricing/`, {
        pricing_id: +id
      });
    }

    if (addRequest) {
      addRequest.then(
        () => {
          notif({
            type: 'success',
            content: 'La ligne a été ajoutée avec succès'
          });
          fetchPricing();
          setNewPricing('');
        },
        (error) => {
          const errors = { api: [error?.response?.data] };
          notif({
            type: 'error',
            content: showFriendlyErrorsHTML(errors, "Une erreur est survenue lors de l'ajout")
          });
        }
      );
    }
  };

  const deletePricing = (pricing: PricingRow) => {
    let deleteRequest: any;
    if (pricing.type === 'additional') {
      deleteRequest = axios.post(`${baseInterventionEndpoint}delete-additional-pricing/`, {
        pricing_id: pricing.id
      });
    } else if (pricing.type === 'applicable') {
      deleteRequest = axios.post(`${baseInterventionEndpoint}delete-applicable-pricing/`, {
        pricing_id: pricing.id
      });
    } else if (pricing.type === 'quotation') {
      deleteRequest = axios.post(`${baseInterventionEndpoint}delete-quotation/`, {
        quotation_id: pricing.id
      });
    }

    if (deleteRequest) {
      confirmDialog({
        title: 'Supprimer la ligne',
        content: 'Êtes-vous sûr de vouloir supprimer cette ligne?',
        onValidate: () => {
          deleteRequest.then(
            () => {
              notif({
                type: 'success',
                content: 'La ligne a été supprimée avec succès'
              });
              fetchPricing();
            },
            (error: any) => {
              const errors = { api: [error?.response?.data] };
              notif({
                type: 'error',
                content: showFriendlyErrorsHTML(errors, "Une erreur est survenue lors de l'ajout")
              });
            }
          );
          closeDialog();
        },
        onCancel: () => {
          closeDialog();
        }
      });
    }
  };

  const uploadPdf = (pdfFormValues: PdfFormValues) => {
    const data = new FormData();
    data.append('price', pdfFormValues.price + '');
    data.append('product_subcontractor_name', pdfFormValues.product_subcontractor_name);
    data.append('product_accounting_code', 'SPE');
    data.append('subcontractor', values.subcontractor);
    data.append('file', pdfFormValues.file, pdfFormValues.file.name);

    axios
      .post(
        `/api/${authService.getEndpoint('boi', 'boe')}/cases/cr-intervention/${
          values.id
        }/file-upload/quotation/`,
        data,
        {
          headers: {
            'Content-Type': 'application/octet-stream',
            'Content-Disposition': 'attachment; filename=' + pdfFormValues.file.name
          }
        }
      )
      .then(() => {
        fetchPricing();
        setShowModalUploadPdf(false);
      });
  };

  return (
    <Paper variant="outlined" square sx={{ mt: 2 }}>
      <Accordion defaultExpanded sx={{ boxShadow: 'none' }} disableGutters={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <h3>Détails sur l'intervention</h3>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <StyledTableHeaderRow>
                  <TableCell>Intervention et action(s) supplémentaire(s)</TableCell>
                  {!readOnly && <TableCell width="50">Action</TableCell>}
                </StyledTableHeaderRow>
              </TableHead>
              <TableBody>
                {pricings && pricings.length > 0 ? (
                  pricings.map((pricing, index) => {
                    return (
                      <TableRow key={`${pricing.type}-${pricing.id}` + index}>
                        <TableCell>{pricing.label}</TableCell>
                        {!readOnly && (
                          <TableCell>
                            <IconButton onClick={() => deletePricing(pricing)}>
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell rowSpan={2} align="center">
                      Aucune donnée
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {!readOnly && (
            <Grid container>
              <Grid item>
                <TextField
                  select
                  onChange={(e) => {
                    setNewPricing(e.target.value);
                  }}
                  value={newPricing || ''}
                  sx={{ width: 400 }}
                  label="Interventions / Actions"
                  id="Interventions / Actions"
                >
                  {availablePricings && availablePricings.length > 0 ? (
                    availablePricings.map((availablePricing, index) => {
                      let disabled = false;
                      const usageNb =
                        (pricings &&
                          pricings.filter((pricing) => pricing.id === availablePricing.id)
                            .length) ||
                        null;
                      const maxUsageLimit = availablePricing.max_usage_limit;
                      if (usageNb && maxUsageLimit) {
                        if (usageNb >= maxUsageLimit) disabled = true;
                      }
                      return (
                        <MenuItem
                          key={`${availablePricing.type}-${availablePricing.id}` + index}
                          value={`${availablePricing.type}::${availablePricing.id}`}
                          disabled={disabled}
                        >
                          <div className="d-flex justify-content-between w-100">
                            <>{availablePricing.label}</>
                            <p className="m-0">{`${availablePricing.max_usage_limit ? `${usageNb || 0} déjà utilisé${usageNb && usageNb > 1 ? 's' : ''} / ${maxUsageLimit} maximum` : ''}`}</p>
                          </div>
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem disabled>No options available</MenuItem>
                  )}
                </TextField>
              </Grid>
              <Grid item>
                <IconButton sx={{ mt: 1, ml: 1 }} disabled={!newPricing} onClick={addPricing}>
                  <DoneIcon fontSize="small" />
                </IconButton>
                <IconButton
                  id="download_pdf"
                  title="Télécharger un devis PDF"
                  sx={{ mt: 1, ml: 1 }}
                  onClick={() => {
                    setShowModalUploadPdf(true);
                  }}
                >
                  <UploadIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          )}
          <BirdzDialog options={dialogOptions} key="dialogModalPricing" />
          <BirdzNotif options={notifOptions} key="notifModalPricing" />
          {showModalUploadPdf && (
            <InterventionPricingsModal
              open={true}
              onClose={() => setShowModalUploadPdf(false)}
              onValidate={(values: PdfFormValues) => {
                uploadPdf(values);
              }}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
}
