import React, { useEffect, useState } from 'react';
import { BirdzDialog, InputField, useDialog } from '@applications-terrains/birdz-react-library';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchContext } from '../../../../contexts/fetchContext';
import ModalWithLoader, { RequestStatus } from '../../../Tools/ModalWithLoader/ModalWithLoader';
import PagePaper from '../../../Tools/Pages/PagePaper';
import { Box, Button, Typography } from '@mui/material';
import PhoneField from '../../../Tools/Fields/PhoneField';
import { Formik, Form } from 'formik';
import { phoneRegExp } from '../../../../utils';
import axios from 'axios';
import { Reader } from './ReadersList';

const ReaderForm = ({ endpoint }: { endpoint: string }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toggleNeedToFetch } = useFetchContext();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(null);
  const [openModal, setOpenModal] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [reader, setReader] = useState<Reader | null>(null);
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();

  useEffect(() => {
    if (id) {
      setRequestStatus('pending');
      setIsFetching(true);
      axios.get(endpoint + id).then((response) => {
        setReader(response.data);
        setTimeout(() => {
          setRequestStatus(null);
          setIsFetching(false);
        }, 400);
      });
    }
  }, [endpoint, id]);

  const handleClose = () => {
    setOpenModal(false);
    navigate(-1);
  };

  const transformItemOnSave = (values: Reader) => {
    const { password, ...rest } = values;
    return password ? values : rest;
  };

  const handleSubmit = (values: Reader) => {
    setRequestStatus('pending');

    const formattedValues = transformItemOnSave(values);
    const method = !id ? 'post' : 'put';
    const url = `${endpoint}${id || ''}`;

    const errorTranslations = {
      'Maximum number of allowed users already reached': "Nombre maximum d'utilisateurs atteint",
      'user already used': 'Utilisateur déjà affecté',
      'email already used': "L'email renseigné est déjà utilisé. Veuillez en saisir un autre",
      default: `Une erreur est survenue lors de la ${!id ? 'création' : 'modification'} du releveur`
    };

    closeDialog();
    return axios[method](url, formattedValues)
      .then(() => {
        setRequestStatus('success');
        setTimeout(() => {
          handleClose();
          toggleNeedToFetch(true);
        }, 1500);
      })
      .catch((err) => {
        setRequestStatus('error');
        if (err.response.status === 500) {
          setErrorMessage(errorTranslations['default']);
          return;
        }
        const errorMessage =
          err.response?.data &&
          'error' in err.response.data &&
          err.response.data.error in errorTranslations
            ? errorTranslations[err.response.data.error as keyof typeof errorTranslations]
            : err.response.data.error === ''
              ? errorTranslations['email already used']
              : errorTranslations['default'];
        setErrorMessage(errorMessage);
      });
  };

  return (
    <ModalWithLoader
      openModal={openModal}
      onClose={() => {
        handleClose();
      }}
      status={requestStatus}
      timeout={requestStatus === 'error' ? 3000 : 1500}
      customErrorMessage={errorMessage}
      action={
        isFetching
          ? 'Récupération des données du releveur'
          : `${!id ? 'Création' : 'Modification'} du releveur`
      }
    >
      {!isFetching && (
        <Formik
          validationSchema={Yup.object({
            name: Yup.string().required('Vous devez renseigner les nom et prénom'),
            email: Yup.string()
              .email('Vous devez renseigner un email valide')
              .required('Vous devez renseigner un email'),
            phone: Yup.string()
              .required('Vous devez renseigner un numéro de téléphone')
              .matches(phoneRegExp, 'Vous devez renseigner un numéro de téléphone valide')
              .min(10, 'Numéro de téléphone trop court')
              .max(11, 'Numéro de téléphone trop long'),
            password: !id
              ? Yup.string().required('Vous devez renseigner un mot de passe')
              : Yup.string()
          })}
          initialValues={
            reader || {
              name: '',
              email: '',
              phone: '',
              password: ''
            }
          }
          onSubmit={(values) => {
            confirmDialog({
              title: `Confirmer ${id ? 'les modifications' : 'la création'}`,
              content: 'Souhaitez-vous confirmer vos saisies ?',
              onValidate: () => {
                handleSubmit(values);
              },
              onCancel: () => {
                handleClose();
              },
              okButtonLabel: 'Oui',
              cancelButtonLabel: 'Non'
            });
          }}
        >
          {({ isValid, dirty }) => {
            return (
              <Form>
                <PagePaper
                  blockScroll
                  title={
                    <Typography variant="overline">
                      {!id ? 'Ajouter' : 'Modifier'} un releveur
                    </Typography>
                  }
                >
                  <Box sx={{ pt: 1 }}>
                    <Box sx={{ height: 80 }}>
                      <InputField name="name" type="text" label="Nom" size="small" fullWidth />
                    </Box>
                    <Box sx={{ height: 80 }}>
                      <PhoneField />
                    </Box>
                    <Box sx={{ height: 80 }}>
                      <InputField name="email" type="email" label="Email" size="small" fullWidth />
                    </Box>
                    <Box sx={{ height: 70 }}>
                      <InputField
                        name="password"
                        type="password"
                        label="Mot de passe"
                        size="small"
                        fullWidth
                      />
                    </Box>
                  </Box>

                  <Box sx={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ m: 2 }}
                      disabled={!dirty || !isValid}
                    >
                      Enregistrer
                    </Button>

                    <Button
                      className="btn-space"
                      onClick={() => {
                        confirmDialog({
                          title: 'Annuler les saisies',
                          content: 'Souhaitez-vous annuler vos saisies ?',
                          onValidate: () => {
                            handleClose();
                          },
                          onCancel: () => {
                            handleClose();
                          },
                          okButtonLabel: 'Oui',
                          cancelButtonLabel: 'Non'
                        });
                      }}
                    >
                      Annuler
                    </Button>
                  </Box>
                </PagePaper>
              </Form>
            );
          }}
        </Formik>
      )}
      <BirdzDialog options={dialogOptions} />
    </ModalWithLoader>
  );
};

export default ReaderForm;
