// utils.ts

import { DataRefsType } from '../../../contexts/FetchDataRefsContext';
import { PaginationType } from './PageSizeSelector';

export const getFullEndpoint = ({
  selfPaginate,
  endpoint,
  pagination,
  order
}: {
  selfPaginate?: boolean;
  endpoint: string;
  pagination: PaginationType;
  order?: string;
}): string =>
  `${endpoint}${endpoint.includes('?') ? '&' : '?'}${selfPaginate ? '' : `size=${pagination.pageSize}&page=${pagination.currentPage}`}&ordering=${order || '-created_at'}`;

export const isFetchEnabled = ({
  dataRefs,
  model,
  fullEndpoint
}: {
  dataRefs: DataRefsType;
  model: string;
  fullEndpoint: string;
}): boolean => {
  return !(model in dataRefs) || (model in dataRefs && dataRefs[model]?.endpoint !== fullEndpoint);
};

type PaginateParams<T> = {
  data: T[]; // Tableau des données
  pageNumber: number; // Numéro de la page
  pageSize: number; // Taille de la page
};

export const getPaginatedElements = <T,>({
  data,
  pageNumber,
  pageSize
}: PaginateParams<T>): T[] => {
  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, data.length);
  return data.slice(startIndex, endIndex);
};
