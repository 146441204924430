import React, { JSX } from 'react';
import Select, { components } from 'react-select';
import { Box } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';

interface ClientOption {
  value: string;
  label: string;
  icon?: JSX.Element;
}

interface NameProps {
  value: number;
  label: string;
}
interface OptionLabelProps {
  values: ClientProps[];
  id: string | undefined;
  name: NameProps;
  updateValue: (val: any) => void;
}

interface ClientProps {
  name: string;
  bconnect_id: string;
}

const Option = (props: any) => (
  <components.Option {...props}>
    <Box className="d-flex align-items-center">
      {props.data.icon}
      {props.data.label}
    </Box>
  </components.Option>
);

const SingleValue = (props: any) => (
  <components.SingleValue {...props}>
    <Box className="d-flex align-items-center">{props.data.label}</Box>
  </components.SingleValue>
);

const OptionLabel = ({ values, id, name, updateValue }: OptionLabelProps) => {
  return (
    <Select
      isClearable={true}
      isDisabled={id ? true : false}
      options={
        values.map(
          (value): ClientOption => ({
            value: value.bconnect_id,
            label: value.name,
            icon: <PlaceIcon sx={{ mr: 1 }} fontSize="small" />
          })
        ) as any
      }
      onChange={(event) => {
        updateValue(event);
      }}
      value={id && name}
      components={{ Option, SingleValue }}
    />
  );
};

export default OptionLabel;
