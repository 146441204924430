import axios, { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import PersonIcon from '@mui/icons-material/Person';
import {
  Bookmark,
  useInterventionFiltersContext
} from '../../Extractor/Interventions/context/interventionsFiltersContext';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  SpeedDial,
  SpeedDialAction,
  Tooltip,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import { getFilterString, removeFalsyValues } from '../../../utils';
import { BirdzNotif, useNotif } from '@applications-terrains/birdz-react-library';
import { isEmpty } from 'lodash';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { formatValue, translateKey } from './const';

export default function Bookmarks() {
  const [bookmarks, setBookmarks] = useState<any[]>([]);

  const {
    currentBookmark,
    setCurrentBookmark,
    setFiltersDataString,
    filtersDataValues,
    setFiltersDataValues
  } = useInterventionFiltersContext();

  const noFav = { icon: <NotInterestedIcon />, name: 'Aucun favori' };

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const fetchBookmarks = async () => {
    const allBookmarks: any[] = [{ icon: <NotInterestedIcon />, name: 'Aucun filtre', id: 0 }];
    await axios.get(`/api/boi/extractor/user-filters/`).then((response) => {
      const data = response.data;

      data.map((item: any) => {
        allBookmarks.push({
          icon: <PersonIcon />,
          name: item.name,
          filters: item.filters,
          id: item.id,
          created_at: item.created_at,
          updated_at: item.updated_at
        });
      });
    });
    setBookmarks(allBookmarks);
  };

  useEffect(() => {
    fetchBookmarks();
  }, []);

  // Fonction pour filtrer et formater les données
  const getFilteredData = () => {
    return Object.entries(filtersDataValues.filterValues).filter(([, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      return value !== null && value !== '' && value !== undefined;
    });
  };

  const filteredData = getFilteredData();

  const [name, setName] = useState<string>(currentBookmark?.name ?? '');
  const [payload, setPayload] = useState<Bookmark>({
    name: '',
    filters: {}
  });

  const { notif, notifOptions } = useNotif();

  useEffect(() => {
    setPayload({
      name: currentBookmark?.name ? currentBookmark.name : name,
      filters: filtersDataValues.formValue
    });
  }, [filtersDataValues]);

  useEffect(() => {
    currentBookmark ? setName(currentBookmark?.name) : setName('');
  }, [currentBookmark]);

  const cancelEdit = () => {
    setOpen(false);
    currentBookmark && setName(currentBookmark?.name);
    currentBookmark &&
      setPayload({ name: currentBookmark?.name, filters: currentBookmark?.filters });
  };

  //display the new favorite
  const handleNewFavorite = async () => {
    await axios.get('/api/boi/extractor/user-filters/').then((response: any) => {
      const data = response.data;
      setCurrentBookmark(data[data.length - 1]);
    });
  };

  const actionOnBookmark = async (action: string, id?: number) => {
    let axiosMethod: (url: string, data?: any) => Promise<AxiosResponse>;
    switch (action) {
      case 'add':
        axiosMethod = axios.post;
        break;
      case 'edit':
        axiosMethod = axios.put;
        break;
      case 'delete':
        axiosMethod = axios.delete;
        break;
      default:
        throw new Error('Invalid action');
    }

    const endpoint = '/api/boi/extractor/user-filters/';
    const url = `${endpoint}${action === 'edit' || action === 'delete' ? id : ''}`;

    const newPayload = {
      ...payload,
      updated_at: currentBookmark?.updated_at
    };

    const body = action === 'add' ? payload : action === 'edit' ? newPayload : undefined;

    try {
      const response = await axiosMethod(url, body);
      switch (action) {
        case 'add':
          handleClose();
          await fetchBookmarks();
          handleNewFavorite();
          break;

        case 'edit':
          setCurrentBookmark(response.data);
          handleClose();
          setTimeout(() => {
            fetchBookmarks();
          }, 1000);
          break;

        case 'delete':
          handleCloseDelete();
          setCurrentBookmark(null);
          setName('');
          setTimeout(() => {
            fetchBookmarks();
          }, 1000);
          setFiltersDataString('');
          setFiltersDataValues({
            formValue: {},
            filterValues: {}
          });
          break;
      }

      notif({
        type: 'success',
        content: `Le favori a été ${action === 'add' ? 'ajouté' : action === 'edit' ? 'modifié' : action === 'delete' ? 'supprimé' : ''} avec succès`
      });
    } catch (error) {
      notif({
        type: 'error',
        content: `Erreur lors de ${action === 'add' ? "l'ajout" : action === 'edit' ? 'la modification' : action === 'delete' ? 'la suppression' : ''} de votre favori`
      });
    }
  };

  const getBookmark = async (id: number) => {
    if (id === 0) {
      setFiltersDataString('');
      setFiltersDataValues({
        formValue: {},
        filterValues: {}
      });
      setCurrentBookmark(null);
    } else {
      bookmarks.find((bookmark) => {
        if (bookmark.id === id) {
          setCurrentBookmark(bookmark);
          setFiltersDataString(getFilterString(removeFalsyValues(bookmark.filters)));
          setFiltersDataValues({
            formValue: bookmark.filters,
            filterValues: bookmark.filters
          });
        }
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <SpeedDial
              ariaLabel="SpeedDial basic example"
              sx={{ position: 'relative' }}
              icon={
                currentBookmark?.id ? (
                  <StarIcon sx={{ color: 'yellow' }} />
                ) : !isEmpty(filtersDataValues.filterValues) ? (
                  <IconButton
                    onClick={handleOpen}
                    disabled={bookmarks.length > 10}
                    sx={{
                      '&:hover': {
                        '& .MuiSvgIcon-root': {
                          color: 'yellow',
                          transition: 'color 0.3s'
                        }
                      }
                    }}
                  >
                    <Tooltip title="Ajouter en favori">
                      <StarOutlineIcon sx={{ color: 'action.active' }} />
                    </Tooltip>
                  </IconButton>
                ) : isEmpty(filtersDataValues.filterValues) ? (
                  <IconButton disabled>
                    <StarOutlineIcon />
                  </IconButton>
                ) : (
                  <StarOutlineIcon />
                )
              }
              FabProps={{
                size: 'small',
                sx: { bgcolor: 'lightgrey', '&:hover': { bgcolor: 'grey' } }
              }}
              direction={'left'}
            >
              {bookmarks.length ? (
                bookmarks.map((bookmark) => (
                  <SpeedDialAction
                    key={bookmark.name}
                    icon={bookmark.icon}
                    tooltipTitle={bookmark.name}
                    onClick={() => {
                      getBookmark(bookmark.id);
                    }}
                  />
                ))
              ) : (
                <SpeedDialAction key={noFav.name} icon={noFav.icon} tooltipTitle={noFav.name} />
              )}
            </SpeedDial>
          </Box>
        </Grid>
        {currentBookmark?.id && (
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              fontSize: '12px'
            }}
          >
            {currentBookmark?.name}
            <Tooltip title="Modifier le favori">
              <IconButton onClick={handleOpen}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Supprimer le favori">
              <IconButton onClick={handleOpenDelete}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            <Dialog
              open={openDelete}
              onClose={handleCloseDelete}
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
            >
              <DialogTitle id="dialog-title">
                {`Supprimer le favori ${currentBookmark.name}`}
              </DialogTitle>
              <DialogContent>
                {`Etes-vous sûr de vouloir supprimer les filtres sélectionnés en favori
                              sous le nom ${currentBookmark.name} ?`}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDelete}>Annuler</Button>
                <Button
                  onClick={() => {
                    currentBookmark && actionOnBookmark('delete', currentBookmark.id);
                  }}
                  color="primary"
                  variant="contained"
                >
                  Confirmer
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        )}
      </Grid>

      <Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">
            {currentBookmark?.id
              ? `Modifier le favori ${currentBookmark?.name}`
              : 'Ajouter un nouveau favori'}
          </DialogTitle>
          <DialogContent>
            {currentBookmark?.id
              ? 'Etes-vous sûr de vouloir modifier les filtres sélectionnés en favori ?'
              : 'Etes-vous sûr de vouloir enregistrer les filtres sélectionnés dans un nouveau favori ?'}

            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={4} sx={{ mt: 1 }}>
                Nom * :
              </Grid>
              <Grid item xs={8}>
                <TextField
                  variant="standard"
                  onChange={(e) => {
                    setName(e.target.value);
                    setPayload({
                      ...payload,
                      name: e.target.value,
                      filters: filtersDataValues.formValue
                    });
                  }}
                  value={name}
                />
              </Grid>
              <Grid item xs={4} sx={{ mt: 1 }}>
                Filtres * :
              </Grid>
              <Grid item xs={8}>
                <List>
                  {filteredData.map(([key, value]) => (
                    <ListItem key={key} sx={{ pt: 0, pl: 0 }}>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1" color="primary">
                            {translateKey(key)}
                          </Typography>
                        }
                        secondary={<Typography variant="body2">{formatValue(value)}</Typography>}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelEdit}>Annuler</Button>
            <Button
              onClick={() => {
                currentBookmark?.id
                  ? actionOnBookmark('edit', currentBookmark?.id)
                  : actionOnBookmark('add');
              }}
              color="primary"
              variant="contained"
            >
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <BirdzNotif options={notifOptions} />
    </>
  );
}
