import React from 'react';
import './dots-loader.scss';
import { Box } from '@mui/material';

const DotsLoader = ({ message }: { message?: string }) => (
  <Box className="d-flex align-items-center" sx={{ color: '#666666' }}>
    {message && <Box sx={{ mr: 0.3 }}>{message}</Box>}
    <Box className="dots-loader">...</Box>
  </Box>
);

export default DotsLoader;
