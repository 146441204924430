import { useMemo } from 'react';
import L, { LatLngExpression, LatLngTuple } from 'leaflet';
import { TourDevice, TourRoute } from '../../types';

const useInitialMapState = (
  devices: TourDevice[],
  route?: TourRoute[]
): { center: LatLngExpression; bounds?: L.LatLngBounds } => {
  return useMemo(() => {
    const allPoints: LatLngTuple[] = [];

    // Récupérer tous les points à partir de la route et des devices
    if (route?.length) {
      allPoints.push(...route.map((point) => [point.latitude, point.longitude] as LatLngTuple));
    }

    if (devices?.length) {
      allPoints.push(
        ...devices.map((device) => {
          return [parseFloat(device.COORD_Y), parseFloat(device.COORD_X)] as LatLngTuple;
        })
      );
    }

    // Si aucun point n'est disponible, définir un center par défaut et zoom par défaut
    if (allPoints.length === 0) {
      return { center: [0, 0] as LatLngTuple }; // Default center and zoom
    }

    // Calculer les bounds et le center
    const bounds = L.latLngBounds(allPoints);
    const center = bounds.getCenter();

    return { center, bounds };
  }, [devices, route]);
};

export default useInitialMapState;
