import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField
} from '@mui/material';
import React, { forwardRef, JSX, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { FormikErrors, useFormikContext } from 'formik';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';
import { DatePickerField, TimePickerField } from '@applications-terrains/birdz-react-library';
import { authService } from '../..';
import { regexp8NegativeDecimals } from './Interventions/InterventionForm';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

type ParamValue = {
  id: number;
  label: string;
  updated_at: number;
  created_at: number;
};

type TechnicianFeedbackProps = {
  readOnly?: boolean;
};

const TechnicianFeedback = forwardRef<HTMLDivElement, any>(
  ({ readOnly = false }: TechnicianFeedbackProps, ref) => {
    const [antennaTypes, setAntennaTypes] = useState<ParamValue[]>();
    const [powerTypes, setPowerTypes] = useState<ParamValue[]>();
    const [cableLengthAntenna, setCableLengthAntenna] = useState<ParamValue[]>();
    const { getFieldProps, errors, isSubmitting, touched, values }: any = useFormikContext();

    const DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow
    });

    L.Marker.prototype.options.icon = DefaultIcon;

    useEffect(() => {
      const fetchAntennaTypes = async () => {
        const endpoint = authService.getEndpoint(
          '/api/boi/params/antennas-type/',
          '/api/boe/params/antennas-type/'
        );
        await axios.get(`${endpoint}?size=200`).then((response) => {
          setAntennaTypes(response.data.results);
        });
      };
      const fetchCableLengthAntenna = async () => {
        const endpoint = authService.getEndpoint(
          '/api/boi/params/cables-length/',
          '/api/boe/params/cables-length/'
        );
        await axios.get(`${endpoint}?size=200`).then((response) => {
          setCableLengthAntenna(response.data.results);
        });
      };
      const fetchPowerTypes = async () => {
        const endpoint = authService.getEndpoint(
          '/api/boi/params/power-supply-type/',
          '/api/boe/params/power-supply-type/'
        );
        await axios.get(`${endpoint}?size=200`).then((response) => {
          setPowerTypes(response.data.results);
        });
      };

      fetchAntennaTypes();
      fetchCableLengthAntenna();
      fetchPowerTypes();
    }, []);

    const getError = (fieldName: string): string => {
      const errorMessage = errors[fieldName as keyof FormikErrors<unknown>];
      return (isSubmitting || touched[fieldName as keyof FormikErrors<unknown>]) && errorMessage
        ? errorMessage
        : '';
    };

    const displayTextField = (
      fieldName: string,
      fieldLabel: string,
      options: { type?: string; endAdornment?: JSX.Element; read_only?: boolean } = {}
    ) => {
      const error = getError(fieldName);
      const isTextarea = options?.type === 'textarea';
      return (
        <TextField
          {...getFieldProps(fieldName)}
          label={fieldLabel}
          multiline={isTextarea}
          rows={4}
          error={error !== ''}
          helperText={error}
          InputProps={{
            endAdornment: options?.endAdornment,
            readOnly: options?.read_only
          }}
          type={options.type ?? 'text'}
          value={
            values[fieldName] === null || typeof values[fieldName] === 'undefined'
              ? ''
              : values[fieldName]
          }
          variant={isTextarea ? 'outlined' : 'standard'}
          sx={{
            m: 1,
            minWidth: 300,
            width: isTextarea ? '100%' : 'auto'
          }}
          disabled={readOnly}
        />
      );
    };

    const displaySelectField = (
      fieldName: string,
      fieldLabel: string,
      fieldValues: Partial<{ id: number; label: string }>[]
    ) => {
      const error = getError(fieldName);
      return (
        <TextField
          label={fieldLabel}
          select
          {...getFieldProps(fieldName)}
          error={error !== ''}
          helperText={error}
          sx={{
            m: 1,
            minWidth: 300
          }}
          value={
            values[fieldName] === null || typeof values[fieldName] === 'undefined'
              ? ''
              : values[fieldName]
          }
          variant="standard"
          disabled={readOnly}
        >
          <MenuItem key={fieldName + '-empty'} value={''} />
          {fieldValues.map((fieldValue) => (
            <MenuItem key={fieldValue.id} value={fieldValue.id}>
              {fieldValue.label}
            </MenuItem>
          ))}
        </TextField>
      );
    };

    const getCoords = (): LatLngTuple | undefined => {
      const latitude = values.latitude_n_field_survey?.replace(',', '.');
      const longitude = values.longitude_n_field_survey?.replace(',', '.');
      if (latitude?.match(regexp8NegativeDecimals) && longitude?.match(regexp8NegativeDecimals)) {
        return [latitude, longitude];
      }
      return;
    };

    const coords = getCoords();

    const boxStyle = {
      border: '1px solid #CCC',
      padding: 2,
      margin: 1
    };
    const labelStyle = {
      fontWeight: 'bold',
      display: 'block',
      color: '#404040',
      marginBottom: 2
    };

    return (
      <Paper variant="outlined" square sx={{ mt: 2 }} ref={ref}>
        <Accordion defaultExpanded sx={{ boxShadow: 'none' }} disableGutters={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <h3>Saisie retour technicien </h3>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs={6}>
                {displayTextField('lessor', 'Bailleur *')}
              </Grid>
              <Grid item xs={6}>
                {displayTextField('lessor_contacts', 'Contact bailleur')}
              </Grid>

              <Grid item xs={6}>
                {displaySelectField('power_type', 'Type alimentation *', powerTypes ?? [])}
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={6}>
                <Box sx={boxStyle}>
                  <span style={labelStyle}>Antenne 1</span>

                  {displaySelectField('antenna_one_type', 'Type', antennaTypes ?? [])}
                  {displayTextField('amp_antenna_one', 'Ampli')}
                  {displayTextField('new_amp_antenna_one', 'Nouvel ampli si remplacement')}
                  {displaySelectField(
                    'cable_length_antenna_one',
                    'Longueur câble',
                    cableLengthAntenna ?? []
                  )}
                  {displaySelectField(
                    'new_cable_length_antenna_one',
                    'Nouvelle longueur câble',
                    cableLengthAntenna ?? []
                  )}
                  {displayTextField('azimut_antenna_one', 'Azimut')}
                  {displayTextField('tilt_antenna_one', 'Tilt')}
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box sx={boxStyle}>
                  <span style={labelStyle}>Antenne 2</span>

                  {displaySelectField('antenna_two_type', 'Type', antennaTypes ?? [])}
                  {displayTextField('amp_antenna_two', 'Ampli')}
                  {displayTextField('new_amp_antenna_two', 'Nouvel ampli si remplacement')}
                  {displaySelectField(
                    'cable_length_antenna_two',
                    'Longueur câble',
                    cableLengthAntenna ?? []
                  )}
                  {displaySelectField(
                    'new_cable_length_antenna_two',
                    'Nouvelle longueur câble',
                    cableLengthAntenna ?? []
                  )}
                  {displayTextField('azimut_antenna_two', 'Azimut')}
                  {displayTextField('tilt_antenna_two', 'Tilt')}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={boxStyle}>
                  <span style={labelStyle}>Emplacement</span>

                  <Grid container>
                    <Grid item xs={5}>
                      {displayTextField(
                        'latitude_n_field_survey',
                        'Latitude relevée sur le terrain *'
                      )}
                      {displayTextField(
                        'longitude_n_field_survey',
                        'Longitude relevée sur le terrain *'
                      )}
                    </Grid>
                    {coords && (
                      <Grid item xs={7} sx={{ mt: '-25px' }}>
                        <MapContainer center={coords} zoom={16} style={{ height: 200 }}>
                          <TileLayer
                            attribution=""
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker position={coords}></Marker>
                        </MapContainer>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      {displayTextField('access_constraints', `Contraintes d'accès`)}
                    </Grid>

                    {/* {authService.canAccessBOE() && ( */}
                    <Grid item xs={12}>
                      {displayTextField(
                        'feedback_from_tech_to_subcontractor',
                        'Message complémentaire',
                        {
                          type: 'textarea',
                          read_only: true
                        }
                      )}
                    </Grid>
                    {/* )} */}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={boxStyle}>
                  <span style={labelStyle}>Ouvrage</span>
                  <Grid container>
                    <Grid item xs={4}>
                      {displayTextField('work_type', 'Type *')}
                    </Grid>
                    <Grid item xs={4}>
                      {displayTextField('height', 'Hauteur', {
                        endAdornment: <InputAdornment position="end">mètres</InputAdornment>
                      })}
                    </Grid>
                    <Grid item xs={4}>
                      {displayTextField(
                        'ground_to_antenna_base_height',
                        "Hauteur du sol au point bas de l'antenne",
                        {
                          endAdornment: <InputAdornment position="end">mètres</InputAdornment>
                        }
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={12}>
                <Box sx={boxStyle}>
                  <span style={labelStyle}>Dates d'intervention</span>
                  <Grid container>
                    <Grid item xs={6}>
                      <FormControl sx={{ minWidth: 400 }}>
                        <DatePickerField
                          label="Date réelle"
                          inputFormat="dd/MM/yyyy"
                          time={false}
                          {...getFieldProps('start_intervention_date')}
                          disabled={readOnly}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl sx={{ minWidth: 400 }}>
                        <TimePickerField
                          label="Date prévisionnelle"
                          {...getFieldProps('scheduled_start_date')}
                          disabled={readOnly}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={6}>
                {displayTextField('new_device_id', 'Nouvelle adresse radio si remplacement')}
              </Grid>
              <Grid item xs={6} />

              <Grid item xs={12}>
                {displayTextField('failure_origin', 'Cause panne *', { type: 'textarea' })}
              </Grid>

              <Grid item xs={12}>
                {displayTextField('solution_applied', 'Solution apportée *', { type: 'textarea' })}
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box sx={boxStyle}>
                      <span style={labelStyle}>Ancien numéro</span>
                      {displayTextField('old_call_number', "N° d'appel")}
                      {displayTextField('old_sim_card_number', 'N° carte SIM')}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={boxStyle}>
                      <span style={labelStyle}>Nouveau numéro (si remplacement)</span>
                      {displayTextField('new_call_number', "N° d'appel")}
                      {displayTextField('new_sim_card_number', 'N° carte SIM')}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
);

TechnicianFeedback.displayName = 'TechnicienFeedback';
export default TechnicianFeedback;
