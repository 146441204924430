import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

import { TourDevice, TourRoute } from '../types';
import useInitialMapState from './hooks/useInitialMapState';
import { MapDevices, MapDrawHandler, MapHelper, MapRoute } from './components/TourMapComponents';

import 'leaflet/dist/leaflet.css';
import './TourMap.scss';
import { useFetchDataRefsContext } from '../../../../../../contexts/FetchDataRefsContext';
import EmptyPageWithLoader from '../../../../../Tools/Pages/EmptyPageWIthLoader';
import DotsLoader from '../../../../../Tools/DotsLoader/DotsLoader';

const TourMap = ({
  route,
  onSelected,
  showDevices = true,
  model
}: {
  route?: TourRoute[];
  onSelected?: (value: TourDevice[]) => void;
  showDevices?: boolean;
  model: string;
}) => {
  const { dataRefs, loadingStatus } = useFetchDataRefsContext();
  const devices = ((dataRefs[model]?.results as TourDevice[]) || []).map((device) =>
    'data' in device
      ? {
          ...device,
          ...device.data,
          MODULE: device.MODULE || device.device_id,
          device_id: device.MODULE || device.device_id
        }
      : device
  );
  const { center, bounds } = useInitialMapState(devices, route);

  return loadingStatus.key === 'tour_devices' && loadingStatus.isLoading ? (
    <EmptyPageWithLoader message={<DotsLoader message="chargement de la map" />} hideSpinner />
  ) : (
    <MapContainer center={center} style={{ height: '550px' }} scrollWheelZoom={false}>
      <TileLayer
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/">CARTO</a>'
      />

      <MapHelper bounds={bounds} />
      <MapDrawHandler devices={devices} onSelected={onSelected} />
      <MapRoute route={route} />
      <MapDevices devices={devices} showDevices={showDevices} />
    </MapContainer>
  );
};

export default TourMap;
