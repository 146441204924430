import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Tooltip
} from '@mui/material';
import axios from 'axios';
import _ from 'lodash/fp';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CrCaseEvents from './CrCaseEvents';
import CrInterventionsHistory from './CrInterventionsHistory';
import {
  Formik
  //  useFormikContext
} from 'formik';
import CrInterventionForm from './CrInterventionForm';
import { Link } from 'react-router-dom';
import CrCasePatrimony from './CrCasePatrimony';
import { CrCaseValues, CrIntervention, CrInterventionEvent } from './Types';
import {
  BirdzDialog,
  InputField,
  SelectField,
  useDialog
} from '@applications-terrains/birdz-react-library';
import { useNotif } from '@applications-terrains/birdz-react-library';
import { BirdzNotif } from '@applications-terrains/birdz-react-library';
import * as Yup from 'yup';
import CrCaseDriveAssociationModal from './CrCaseDriveAssociationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { FaGoogleDrive } from 'react-icons/fa';
import { CaseEventsHelper, InterventionEventsHelper } from '../utils/EventsHelper';
import {
  formatToSelectOptions,
  useGetCaseStatuses,
  useGetCaseTypes
} from '../../../hooks/datarefs';
import CrCaseStatus from './CrCaseStatus';
import formatErrorNotifObject from '../../Tools/formatErrorNotifObject';
import NotifWithCountdown from '../../Tools/NotifWithCountdown';
import { authService } from '../../..';

// type FormErrorNotificationProps = {
//   onError(error: string): void;
// };

const CrCaseForm = () => {
  const addInterventionRef = useRef<HTMLDivElement>(null);
  const [crCase, setCrCase] = useState<Partial<CrCaseValues>>({});
  const [isLoading, setLoading] = useState<boolean>(false);
  const [viewIntervention, setViewIntervention] = useState<any>();
  const [backupViewIntervention, setViewBackupIntervention] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const { confirmDialog, closeDialog, dialogOptions } = useDialog();
  const { notif, notifOptions } = useNotif();
  const location = useLocation();
  const [crIsReadonly, setCrIsReadonly] = useState<boolean>(false);
  // const [formHasError, setFormHasError] = useState<boolean>(false);
  const navigate = useNavigate();

  const { id } = useParams();
  const endpoint = `/api/boi/cases/cr-cases/${id}/`;

  const [interventionToSave, setInterventionToSave] = useState<CrIntervention>();
  const [interventionEventsToSave, setInterventionEventsToSave] = useState<CrInterventionEvent[]>();
  const [showNotifWithCountdown, setShowNotifWithCountdown] = useState(false);

  const { data: caseStatuses } = useGetCaseStatuses();
  const { data: caseTypes } = useGetCaseTypes();
  const [error, setError] = useState<string>('');

  const loadCRCase = useCallback(async () => {
    return axios
      .get(endpoint)
      .then(
        (response: any) => {
          setCrCase(response.data);
          if (CaseEventsHelper.hasClosedEvent(response.data.events_history)) {
            setCrIsReadonly(true);
          } else {
            setCrIsReadonly(false);
          }

          if (location.hash === '#createInter') {
            setTimeout(() => {
              addInterventionRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 1000);
          } else if (
            response.data.interventions_history &&
            response.data.interventions_history.length > 0
          ) {
            // Load last inter
            const lastIntervention =
              response.data.interventions_history[response.data.interventions_history.length - 1];
            setViewIntervention(lastIntervention);
            setViewBackupIntervention(lastIntervention);
          }
        },
        (error: any) => {
          notif({
            type: 'error',
            content: 'Une erreur est survenue lors du chargement du dossier'
          });
          let errorMessage = 'Une erreur est survenue lors du chargement du dossier.';
          errorMessage += error?.response?.status === 404 ? ' Dossier non trouvé.' : '';
          setError(errorMessage);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  }, [endpoint]);

  useEffect(() => {
    setLoading(true);
    loadCRCase();
  }, [endpoint, loadCRCase]);

  const allInterventionsAreClosed = () => {
    const interventionsNotClosed: CrIntervention[] = [];

    if (crCase?.interventions_history) {
      crCase.interventions_history.forEach((intervention: CrIntervention) => {
        if (!InterventionEventsHelper.hasClosedEvent(intervention.events_history)) {
          interventionsNotClosed.push(intervention);
        }
      });
    }

    return interventionsNotClosed.length === 0;
  };

  const closeCrCase = () => {
    confirmDialog({
      content: 'Souhaitez-vous clore ce dossier?',
      onValidate: () => {
        if (!allInterventionsAreClosed()) {
          notif({
            type: 'error',
            content: 'Il y a des interventions non clôturées dans ce dossier.'
          });
          return;
        }

        axios.post(`${endpoint}close/`, crCase?.device_id).then((response: any) => {
          const data: CrCaseValues = response.data;
          notif({
            type: 'success',
            content: 'Le dossier a bien été clos'
          });
          setCrCase(data);
        });
        closeDialog();
      },
      onCancel: () => {
        closeDialog();
      },
      okButtonLabel: 'Oui',
      cancelButtonLabel: 'Non'
    });
  };

  const goToInterventionForm = () => {
    if (addInterventionRef?.current !== null) {
      addInterventionRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const lastInterventionsHistoryId =
    !crCase?.interventions_history || crCase.interventions_history.length === 0
      ? ''
      : [...crCase.interventions_history].slice(-1)[0].id;

  const saveIntervention = async () => {
    if (!interventionToSave || _.isEqual(interventionToSave, backupViewIntervention)) return;

    const payload: any = Object.assign({}, interventionToSave);
    payload.cr_case = crCase.id;

    if (interventionToSave.id) {
      const response = await axios.put(
        `/api/boi/cases/cr-interventions/${interventionToSave.id}/`,
        payload
      );
      setViewBackupIntervention(response.data);
      notif({
        type: 'success',
        content: "L'intervention a été modifiée"
      });
      document.getElementById('crCaseForm')?.scrollIntoView();
    } else {
      await axios.post(`/api/boi/cases/cr-interventions/`, payload);
      notif({
        type: 'success',
        content: "L'intervention a été créée"
      });
      document.getElementById('crCaseForm')?.scrollIntoView();
    }
  };

  const saveInterventionEvents = async (interventionId: number) => {
    if (interventionId && interventionEventsToSave?.length) {
      const allPromises: Promise<any>[] = [];
      interventionEventsToSave.forEach((eventToSave) => {
        const payload = Object.assign({}, eventToSave);
        payload.intervention = interventionId;

        if (eventToSave?.id && typeof eventToSave?.id === 'number') {
          allPromises.push(
            axios.put(`/api/boi/cases/events-cr-interventions/${eventToSave.id}/`, payload)
          );
        } else {
          if (payload.id) delete payload.id; // remove tmp id if exists
          allPromises.push(axios.post(`/api/boi/cases/events-cr-interventions/`, payload));
        }
      });
      await Promise.allSettled(allPromises);
    }
  };

  const saveCRCase = async (values: any) => {
    if (interventionToSave) {
      await saveIntervention();
    }
    if (interventionEventsToSave) {
      const interventionId = interventionToSave?.id || viewIntervention.id;
      await saveInterventionEvents(interventionId);
    }

    setInterventionToSave(undefined);
    setInterventionEventsToSave(undefined);

    axios.put(endpoint, values).then(
      (response: any) => {
        setCrCase(response.data);
        setShowNotifWithCountdown(true);
      },
      () => {
        notif({
          type: 'error',
          content: 'Une erreur est survenue lors de la modification du dossier'
        });
      }
    );
  };

  const observationButtonIsVisible = () => {
    // If already observable status
    if (crCase.status === 5) {
      return false;
    }

    const firstOpenedIntervention = crCase?.interventions_history?.find((intervention) => {
      return !InterventionEventsHelper.hasClosedEvent(intervention.events_history);
    });
    if (firstOpenedIntervention) {
      return InterventionEventsHelper.hasTechnicianClosed(firstOpenedIntervention.events_history);
    }
    return false;
  };

  const observe = () => {
    confirmDialog({
      content: 'Souhaitez-vous passer le dossier en observation?',
      onValidate: () => {
        axios.post(`/api/boi/cases/cr-cases/${crCase.id}/observe/`).then(
          () => {
            notif({
              type: 'success',
              content: 'Le dossier a bien été passé en observation'
            });
            loadCRCase();
          },
          () => {
            notif({
              type: 'error',
              content: 'Une erreur est survenue durant le passage du dossier en observation'
            });
          }
        );
        closeDialog();
      },
      onCancel: () => {
        closeDialog();
      },
      okButtonLabel: 'Oui',
      cancelButtonLabel: 'Non'
    });
  };

  const cancel = async (crCase: CrCaseValues) => {
    if (
      crCase.type ||
      crCase.events_history.length !== 1 ||
      crCase.interventions_history.length !== 0
    ) {
      window.location.reload();
    } else {
      axios.delete(`/api/boi/cases/cr-cases/${crCase.id}/`).then(() => {
        navigate('/boi/cr/cases');
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isLoading ? (
        <Box textAlign="center">
          <CircularProgress />
          <h3>Chargement en cours...</h3>
        </Box>
      ) : error ? (
        <>
          <Alert severity="error">{error}</Alert>
        </>
      ) : (
        <Paper variant="outlined" square sx={{ p: 2 }} id="crCaseForm">
          <h3>Informations générales</h3>

          <Formik
            initialValues={crCase}
            enableReinitialize={true}
            onSubmit={async (values) => {
              await saveCRCase(values);
              return;
            }}
            validationSchema={Yup.object().shape({
              number: Yup.mixed().required('Champ obligatoire'),
              type: Yup.mixed().required('Champ obligatoire'),
              status: Yup.mixed().required('Champ obligatoire'),
              internal_comments: Yup.string().nullable()
            })}
          >
            {(props: any) => {
              const {
                isSubmitting,
                errors,
                setFieldError,
                setSubmitting,
                handleSubmit,
                values,
                setFieldValue
              } = props;

              return (
                <form
                  id="topForm"
                  // noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    setSubmitting(true);
                    // Check
                    if (Object.keys(errors).length === 0) {
                      handleSubmit();
                    } else {
                      notif(
                        formatErrorNotifObject({
                          error: errors || 'Une erreur est survenue lors de la création du dossier'
                        })
                      );
                    }
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={7}>
                      <Stack spacing={2}>
                        <FormControl>
                          <TextField
                            name="number"
                            id="number"
                            inputProps={{ readOnly: true }}
                            value={crCase?.number}
                            label="Numéro dossier"
                            variant="filled"
                            size="small"
                            sx={{ width: 300 }}
                          />
                        </FormControl>
                        <Box className="d-flex" gap={2}>
                          <Box className="d-flex flex-column" gap={2}>
                            <FormControl>
                              <SelectField
                                name="type"
                                label="Type"
                                id="type"
                                options={formatToSelectOptions(caseTypes)}
                                required={true}
                                sx={{ width: 300 }}
                                disabled={crIsReadonly}
                              />
                            </FormControl>
                            <FormControl>
                              <SelectField
                                name="status"
                                id="status"
                                label="Statut"
                                options={formatToSelectOptions(caseStatuses)}
                                required={true}
                                sx={{ width: 300 }}
                                disabled={crIsReadonly}
                              />
                            </FormControl>
                          </Box>
                          {authService.canAccessBOI() && (
                            <FormControl fullWidth>
                              <InputField
                                name="internal_comments"
                                id="internal_comments"
                                multiline
                                rows={2.75}
                                fullWidth
                                label="Commentaires"
                              />
                            </FormControl>
                          )}
                        </Box>
                      </Stack>
                    </Grid>

                    <Grid item xs={12} md={5} textAlign="right" sx={{ width: 300 }}>
                      <Box sx={{ marginTop: '-50px' }}>
                        <CrCaseStatus crCaseStatusId={values?.status} />
                      </Box>
                      {_.isEmpty(crCase?.interventions_history) ? (
                        <Button
                          id="createCrCase"
                          variant="contained"
                          onClick={goToInterventionForm}
                          disabled={crIsReadonly}
                        >
                          Ajouter une intervention
                        </Button>
                      ) : (
                        <Link
                          to={`/boi/cr/interventions/edit/${lastInterventionsHistoryId}#retour`}
                        >
                          <Button id="seeInfos" variant="contained">
                            Voir retour intervention
                          </Button>
                        </Link>
                      )}

                      <Box
                        sx={{
                          mt: 3
                        }}
                      >
                        <Stack
                          direction="row"
                          sx={{ display: 'flex' }}
                          alignItems={'center'}
                          justifyContent={'right'}
                        >
                          {!values.folder_id ? (
                            <Button
                              variant="outlined"
                              onClick={handleClickOpen}
                              sx={{ mr: 3 }}
                              disabled={crIsReadonly}
                            >
                              Association drive
                              <Box sx={{ ml: 1 }}>
                                <FaGoogleDrive />
                              </Box>
                            </Button>
                          ) : (
                            <>
                              <FaGoogleDrive />
                              <Box sx={{ ml: 1 }}>
                                Association drive : <strong>{values.folder_name}</strong>
                              </Box>
                              {!crIsReadonly && (
                                <Tooltip title="Supprimer l'association au drive">
                                  <IconButton
                                    id="folder_button"
                                    onClick={() => {
                                      setFieldValue('folder_id', undefined);
                                      setFieldValue('folder_name', undefined);
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Stack>

                        <CrCaseDriveAssociationModal
                          selectedValue={values.folder_id}
                          open={open}
                          onClose={(
                            folderId: string | undefined,
                            driveName: string | undefined
                          ) => {
                            handleClose();
                            if (folderId) {
                              setFieldValue('folder_id', folderId);
                              setFieldValue('folder_name', driveName);
                            } else {
                              setFieldValue('folder_id', null);
                              setFieldValue('folder_name', null);
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <CrCaseEvents
                    crEvents={crCase?.events_history || []}
                    id={id ? parseInt(id) : undefined}
                    onEventsChange={(crEvents: CrInterventionEvent[]) => {
                      const newResults: any = { ...crCase };
                      newResults.events_history = crEvents;
                      setCrCase(newResults);
                    }}
                    readOnly={crIsReadonly}
                  />

                  <CrCasePatrimony results={crCase?.patrimony} />

                  <CrInterventionsHistory
                    interventions={crCase.interventions_history || []}
                    goToInterventionForm={goToInterventionForm}
                    onInterventionClick={(intervention) => {
                      setViewIntervention(intervention);
                    }}
                    canCreateIntervention={!crIsReadonly && allInterventionsAreClosed()}
                  />

                  <CrInterventionForm
                    // setFormHasError={setFormHasError}
                    isSubmitting={isSubmitting}
                    intervention={viewIntervention}
                    ref={addInterventionRef}
                    onInterventionChange={(intervention) => {
                      setFieldError('intervention', undefined);
                      setInterventionToSave(intervention);
                    }}
                    onInterventionEventsChange={(eventsToSave) => {
                      setInterventionToSave(viewIntervention);
                      setInterventionEventsToSave(eventsToSave);
                    }}
                    onClickCreateIntervention={() => {
                      setViewIntervention({});
                    }}
                    onError={(errs) => {
                      setFieldError('intervention', errs);
                    }}
                    canCreateIntervention={!crIsReadonly && allInterventionsAreClosed()}
                    readOnly={crIsReadonly}
                  />

                  <Box sx={{ textAlign: 'center' }}>
                    {observationButtonIsVisible() && (
                      <Button
                        id="observation"
                        variant="outlined"
                        onClick={() => {
                          observe();
                        }}
                        sx={{ m: 2 }}
                      >
                        Observation
                      </Button>
                    )}
                    <Tooltip
                      id="not_all_interventions_closed"
                      title={
                        !allInterventionsAreClosed() ? (
                          <>Il y a des interventions non clôturées dans ce dossier.</>
                        ) : (
                          ''
                        )
                      }
                    >
                      <span>
                        <Button
                          id="closeCrCase"
                          variant="outlined"
                          color="primary"
                          sx={{ m: 2 }}
                          onClick={closeCrCase}
                          disabled={!allInterventionsAreClosed() || values?.status__name === 'Clos'}
                        >
                          Clore dossier
                        </Button>
                      </span>
                    </Tooltip>
                    <Button
                      id="submitCrCase"
                      type="submit"
                      variant="contained"
                      form="topForm"
                      sx={{ m: 2 }}
                      // disabled={formHasError || crIsReadonly || Object.keys(errors).length > 0}
                      disabled={!values.type || !values.status || crIsReadonly}
                    >
                      Enregistrer
                    </Button>
                    <Button
                      id="cancelCrCase"
                      form="topForm"
                      sx={{ m: 2 }}
                      onClick={() => {
                        confirmDialog({
                          content: 'Souhaitez-vous annuler vos saisies?',
                          onValidate: () => {
                            cancel(values);
                            closeDialog();
                          },
                          onCancel: () => {
                            closeDialog();
                          },
                          okButtonLabel: 'Oui',
                          cancelButtonLabel: 'Non'
                        });
                      }}
                    >
                      Annuler
                    </Button>
                  </Box>
                </form>
              );
            }}
          </Formik>
          <BirdzDialog options={dialogOptions} />
          <BirdzNotif options={notifOptions} />
          {showNotifWithCountdown && (
            <NotifWithCountdown
              action={() => navigate('/boi/cr/cases')}
              notifObj={{ type: 'success', content: 'Le dossier a été enregistré avec succès' }}
            />
          )}
        </Paper>
      )}
    </>
  );
};
export default CrCaseForm;
