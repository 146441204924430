import { saveAs } from 'file-saver';
import * as Cookies from 'es-cookie';
import { ExportButton } from '@applications-terrains/birdz-react-library';
import axios from 'axios';
import { PaginationType } from '../PageSizeSelector';

export const launchExport = ({
  endpoint,
  requestMethod = 'GET',
  responseType = 'blob',
  fileName,
  onSuccess
}: ExportButton & {
  endpoint: string;
  pagination: PaginationType;
}) => {
  const axiosInstance = axios.create();
  axiosInstance
    .request({
      method: requestMethod,
      url: endpoint,
      headers: {
        Accept: '*/*',
        'X-CSRFToken': Cookies.get('csrftoken') || ''
      },
      responseType
    })
    .then((response) => {
      if (responseType === 'blob') {
        if (!fileName && response.headers && response.headers['content-disposition']) {
          const [, headerFilename] = response.headers['content-disposition'].split('filename=');
          saveAs(response.data, headerFilename);
        } else if (fileName) {
          saveAs(response.data, fileName);
        }
      }

      if (typeof onSuccess === 'function') {
        onSuccess(response.data);
      }
    });
};
