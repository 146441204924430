import React from 'react';
import BTable from '../../../../../Tools/Table/BTable';
import { deviceStatuses } from '../TourMap/TourMapUtils';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import CheckIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';

import { CollectedFrameType, TourDetail, TourDevice, TourDeviceProps } from '../types';
import { Accordion, AccordionDetails, AccordionSummary, Box, Tooltip } from '@mui/material';
import { useFetchDataRefsContext } from '../../../../../../contexts/FetchDataRefsContext';
import moment from 'moment';
import TourMap from '../TourMap/TourMap';
import ReadingState from './ReadingState';

const TourDetailsDevices = ({ endpoint, tour }: { endpoint: string; tour: TourDetail }) => {
  const {
    dataRefs
    //  setDataRefs
  } = useFetchDataRefsContext();

  return (
    <BTable
      // onRowsSelect={(rows) => {
      //   setDataRefs({ ...dataRefs, tour_devices_selected: { count: rows.length, results: rows } });
      // }}
      extraComponent={
        <Box
          sx={{
            mt: 2,
            borderRadius: '4px',
            overflow: 'hidden',
            border: '1px solid',
            borderColor: 'divider',
            minHeight: '550px'
          }}
        >
          <TourMap
            model={
              dataRefs['tour_devices_selected'] && dataRefs['tour_devices_selected']?.count > 0
                ? 'tour_devices_selected'
                : dataRefs['tour_devices_filtered']
                  ? 'tour_devices_filtered'
                  : 'tour_devices'
            }
            route={tour?.tour_route?.tour_route ? tour.tour_route.tour_route : []}
          />
        </Box>
      }
      selfPaginate
      endpoint={endpoint}
      fields={[
        {
          name: 'data',
          label: 'Adresse',
          labelIcon: <LocalPostOfficeIcon color="action" fontSize="small" />,
          transform: (value: TourDevice) => {
            return (
              `${value?.NUM_RUE_ABO ?? '-'} ${value?.ATTR_NUM_RUE_ABO ?? ''} ${value?.RUE_ABO ?? '-'} ${value?.COMMUNE_ABO ?? '-'}` ||
              '-'
            );
          }
        },
        {
          name: 'data',
          label: 'Compteur',
          labelIcon: <GasMeterIcon color="action" fontSize="small" />,
          transform: (value: TourDevice) => {
            return `${value?.COMPTEUR || '-'}`;
          }
        },
        {
          name: 'data',
          label: 'Module',
          labelIcon: <ElectricMeterIcon color="action" fontSize="small" />,
          transform: (_, object: TourDeviceProps) => {
            return object?.device_id || '-';
          }
        },
        {
          name: 'reading_state',
          label: 'Statut',
          labelIcon: <CheckIcon color="action" fontSize="small" />,
          transform: (value: TourDeviceProps['reading_state']) => {
            return value in deviceStatuses ? <ReadingState value={value} /> : '-';
          }
        },
        {
          name: 'data',
          label: 'PDS',
          labelIcon: <OnlinePredictionIcon color="action" fontSize="small" />,
          transform: (value: TourDevice) => {
            return value?.PDS || '-';
          }
        },
        {
          name: 'results',
          label: 'Relevés',
          labelIcon: <PunchClockIcon color="action" fontSize="small" />,
          transform: (value: TourDeviceProps['results']) => {
            return value?.collected_frames && value?.collected_frames?.length > 0 ? (
              <ResultsDropdown collectedFrames={value.collected_frames} />
            ) : (
              '-'
            );
          }
        }
      ]}
      model={dataRefs['tour_devices_filtered'] ? 'tour_devices_filtered' : 'tour_devices'}
    />
  );
};

export default TourDetailsDevices;

const ResultsDropdown = ({ collectedFrames }: { collectedFrames: CollectedFrameType }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  return (
    <Accordion
      disabled={false}
      variant="outlined"
      sx={{
        border: 'none',
        backgroundColor: 'transparent',
        m: 0,
        '& .MuiAccordionSummary-root': {
          minHeight: 20 // Supprime la marge du contenu
        }
      }}
      expanded={expanded}
      disableGutters={true}
    >
      <AccordionSummary
        onClick={() => setExpanded(!expanded)}
        expandIcon={<ExpandMoreIcon />}
        sx={{
          m: 0,
          mt: -0.3,
          p: 0,
          '& .MuiAccordionSummary-content': {
            margin: 0 // Supprime la marge du contenu
          }
        }}
      >
        {expanded ? (
          <Box
            className="d-flex align-items-center"
            sx={{ borderBottom: '1px solid', borderColor: 'divider', opacity: 0.6 }}
          >
            <Box sx={{ width: 30 }}>
              <Tooltip title="Type de relevé" placement="top">
                <SummarizeIcon color="info" fontSize="small" />
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="Date / heure du relevé" placement="top">
                <PendingActionsIcon color="info" fontSize="small" />
              </Tooltip>
            </Box>
          </Box>
        ) : (
          <Box sx={{ fontWeight: 500, color: '#666666' }}>
            {collectedFrames?.map((el) => el.frame_type_id).join(', ')}
          </Box>
        )}
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {collectedFrames?.map((el, index) => (
          <Box
            key={el.frame_type_id.toString() + el.datetime}
            sx={{
              pt: 0.5,
              pb: index !== collectedFrames.length - 1 ? 0.5 : 0,
              whiteSpace: 'nowrap',
              borderBottom: index !== collectedFrames.length - 1 ? '1px solid' : 'none',
              borderColor: 'divider'
            }}
            className="d-flex align-items-center"
          >
            <Box sx={{ width: 30, fontWeight: 500, color: '#666666' }}>{el.frame_type_id}</Box>
            {moment(el.datetime).format('DD/MM/YYYY à HH:mm:ss')}
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
