import { TourDevice } from '../types';

export const removeDuplicatesByProperties = (array: TourDevice[]): TourDevice[] => {
  const occurrences: { [key: string]: number } = {}; // Objet pour compter les occurrences

  // Comptage des occurrences pour chaque élément (basé sur les clés uniques)
  array.forEach((item) => {
    const key = item.id || item.MODULE || item.device_id; // Clé unique basée sur les propriétés
    if (key) {
      occurrences[key] = (occurrences[key] || 0) + 1; // Incrémente le compteur pour la clé
    }
  });

  // Filtrage : garde uniquement les éléments ayant une seule occurrence
  return array.filter((item) => {
    const key = item.id || item.MODULE || item.device_id;
    return key && occurrences[key] === 1; // Garde si la clé n'apparaît qu'une seule fois
  });
};

export const sortWithPriority = (
  items: TourDevice[],
  selectedDevices: TourDevice[]
): TourDevice[] => {
  const priorityIds = new Set(selectedDevices.map((item) => item.MODULE || item.device_id));

  return items.sort((a, b) => {
    const aInPriority = priorityIds.has(a.MODULE || a.device_id);
    const bInPriority = priorityIds.has(b.MODULE || b.device_id);

    if (aInPriority && !bInPriority) {
      return -1; // a vient avant b
    } else if (!aInPriority && bInPriority) {
      return 1; // b vient avant a
    } else {
      return 0; // l'ordre reste inchangé pour les éléments non prioritaires
    }
  });
};

export const sortDevices = (devices: TourDevice[]): TourDevice[] => {
  // Copie du tableau original pour ne pas le modifier directement
  const sortedDevices = [...devices];

  sortedDevices.sort((a, b) => {
    // Si l'un des éléments n'a pas de frame_type_data, le placer à la fin
    if (!a.frame_type_data) return 1;
    if (!b.frame_type_data) return -1;

    // Si les deux éléments ont frame_type_data null, ne pas changer leur ordre
    if (a.frame_type_data === null && b.frame_type_data === null) return 0;

    // Si un seul élément a frame_type_data null, le placer à la fin
    if (a.frame_type_data === null) return 1;
    if (b.frame_type_data === null) return -1;

    // Trier par le nombre d'éléments dans frame_type_data (du plus grand au plus petit)
    if (a.frame_type_data.length !== b.frame_type_data.length) {
      return b.frame_type_data.length - a.frame_type_data.length;
    }

    // Si le nombre d'éléments est le même, trier par les IDs
    const aIds = a.frame_type_data.map((item) => item.id).sort((x, y) => x - y);
    const bIds = b.frame_type_data.map((item) => item.id).sort((x, y) => x - y);

    // Comparer les IDs un par un
    for (let i = 0; i < aIds.length; i++) {
      if (aIds[i] !== bIds[i]) {
        return aIds[i] - bIds[i];
      }
    }

    // Si tous les IDs sont identiques, ne pas changer l'ordre
    return 0;
  });

  return sortedDevices;
};
