import React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { ModuleData } from './CR.types';

type DeviceMapProps = {
  moduleData: ModuleData;
};

// Création d'une icône personnalisée
const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41]
});

L.Marker.prototype.options.icon = DefaultIcon;

const DeviceMap = ({ moduleData }: DeviceMapProps) => {
  const modulePosition: LatLngExpression = { lat: moduleData.latitude, lng: moduleData.longitude };

  return (
    <MapContainer
      center={modulePosition}
      zoom={13}
      style={{ height: '550px', width: '100%' }}
      scrollWheelZoom={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={modulePosition}></Marker>
    </MapContainer>
  );
};

export default DeviceMap;
