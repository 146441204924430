import React from 'react';
import { useTourFormContext } from '../context/tourFormContext';
import TourMap from '../TourMap/TourMap';
import { Box } from '@mui/material';

const TourMapWrapper = () => {
  const {
    tourData,
    setSelectedDevices,
    setRefreshListPage,
    refreshDevicesStep,
    setShowFrameTypeModal
  } = useTourFormContext();
  const devices = tourData.patrimonyStep;

  return (
    <>
      {devices && !refreshDevicesStep && (
        <Box
          sx={{
            borderRadius: '4px',
            overflow: 'hidden',
            border: '1px solid',
            borderColor: 'divider',
            minHeight: '550px'
          }}
        >
          <TourMap
            model={tourData.model || 'tour_devices'}
            onSelected={(value) => {
              setSelectedDevices(value);
              setShowFrameTypeModal(true);
              setRefreshListPage(true);
            }}
          />
        </Box>
      )}
    </>
  );
};

export default TourMapWrapper;
