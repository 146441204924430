import React from 'react';
import { Box } from '@mui/material';
import { TourDeviceProps } from '../types';
import { deviceStatuses } from '../TourMap/TourMapUtils';

const ReadingState = ({ value }: { value: TourDeviceProps['reading_state'] }) => {
  const colors = {
    PARTIALLY: '#F7A677',
    TOCOLLECT: '#95CDE8',
    COLLECTED: '#7ED17C',
    ERROR: '#F44336',
    FAILURE: '#F44336'
  };
  return (
    <Box className="d-flex align-items-center" sx={{ flexWrap: 'nowrap' }}>
      <Box
        sx={{
          backgroundColor: colors[value as keyof typeof colors],
          width: '8px',
          height: '8px',
          borderRadius: '50%',
          mr: 1,
          mt: 0.25
        }}
      ></Box>
      <Box sx={{ textWrap: 'nowrap' }}>{deviceStatuses[value]}</Box>
    </Box>
  );
};

export default ReadingState;
