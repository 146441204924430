import { useState, useEffect, useMemo } from 'react';
import { useFetchDataRefsContext } from '../contexts/FetchDataRefsContext';

type PaginationType = {
  pageSize: number;
  currentPage: number;
  totalItems: number | null;
};

const usePagination = ({ initialPageSize, model }: { initialPageSize?: number; model: string }) => {
  const { dataRefs } = useFetchDataRefsContext();
  const [pagination, setPagination] = useState<PaginationType>({
    pageSize: initialPageSize || 25,
    currentPage: 1,
    totalItems: null
  });

  // Charger la taille de page depuis le localStorage
  useEffect(() => {
    const savedPageSize = localStorage.getItem('pageSize');
    if (savedPageSize) {
      const parsedPageSize = parseInt(savedPageSize, 10);
      if (parsedPageSize !== pagination.pageSize) {
        setPagination((prev) => ({ ...prev, pageSize: parsedPageSize }));
      }
    }
  }, []);

  // Gérer les changements de totalItems ou model
  useEffect(() => {
    if (dataRefs && model in dataRefs) {
      const itemCount = dataRefs[model]?.count || 0;

      setPagination((prev) => {
        // Réinitialiser `currentPage` à 1 si totalItems change
        if (itemCount !== prev.totalItems) {
          return {
            ...prev,
            totalItems: itemCount,
            currentPage: 1
          };
        }
        return prev;
      });
    }
  }, [dataRefs, model]);

  // Calculer la chaîne de résultats (resultString)
  const resultString = useMemo(() => {
    if (!pagination.totalItems) return `résultat 0 / 0`;

    const { pageSize, currentPage, totalItems } = pagination;
    const start = (currentPage - 1) * pageSize + 1;
    const end = Math.min(currentPage * pageSize, totalItems);
    const plural = totalItems > 1 ? 's' : '';

    return `résultat${plural} ${start} - ${end} / ${totalItems}`;
  }, [pagination]);

  return { pagination, setPagination, resultString };
};

export default usePagination;
