import React, { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { ListField } from '@applications-terrains/birdz-react-library';
import { Box, Checkbox, TableCell, TableRow } from '@mui/material';
import { css } from '@emotion/react';

const rowStyle = css`
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #f5f5f5;
  }
`;

const CustomHeaderCell = ({ text }: { text: string }) => {
  const [hover, setHover] = useState(false);
  const [textFits, setTextFits] = useState(true);
  const [maxTextWidth, setMaxTextWidth] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const noWrapRef = useRef<HTMLDivElement | null>(null);
  const wrapRef = useRef<HTMLDivElement | null>(null);
  const [translateDistance, setTranslateDistance] = useState(0);

  // Vérifier si le texte dépasse la largeur de la box
  useEffect(() => {
    if (wrapperRef.current && noWrapRef.current && wrapRef.current) {
      const wrapperWidth = wrapperRef.current.clientWidth;
      const noWrapRefWidth = noWrapRef.current.clientWidth;
      const wrapRefWidth = wrapRef.current.clientWidth;

      setMaxTextWidth(wrapRefWidth);
      setTextFits(noWrapRefWidth <= wrapperWidth);
      setTranslateDistance(noWrapRefWidth - wrapperWidth); // Calculer la distance de déplacement
    }
  }, [text]);

  return (
    <Box className="w-100 d-flex align-items-center" ref={wrapperRef}>
      <Box
        ref={noWrapRef}
        sx={{
          position: 'absolute',
          whiteSpace: 'nowrap',
          height: 0,
          overflow: 'hidden'
        }}
      >
        {text}
      </Box>
      <Box
        ref={wrapRef}
        sx={{
          position: 'absolute',
          height: 0,
          overflow: 'hidden'
        }}
      >
        {text}
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          maxWidth: maxTextWidth, // La box garde une largeur adaptative
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          color: '#666666',
          cursor: !textFits ? 'pointer' : 'default'
        }}
        onMouseEnter={() => !textFits && setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Box
          sx={{
            display: 'inline-block',
            width: '100%',
            transform: hover ? `translateX(-${translateDistance}px)` : 'translateX(0%)',
            transition: hover ? 'transform 0.5s linear' : 'none'
          }}
        >
          {text}
        </Box>
        {!textFits && !hover && (
          <Box sx={{ position: 'absolute', right: 0, top: 0, backgroundColor: '#fff', pl: '2px' }}>
            ...
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const BTableHeadRow = ({
  fields,
  actions,
  handleSelectAll,
  selected,
  indeterminate
}: {
  fields: (ListField & { labelIcon?: ReactNode })[];
  actions?: any[];
  handleSelectAll?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: boolean;
  indeterminate?: boolean;
}) => (
  <TableRow>
    {handleSelectAll && (
      <TableCell padding="checkbox">
        <Checkbox
          className={selected || indeterminate ? 'theme-color' : ''}
          size="small"
          indeterminate={indeterminate}
          checked={selected}
          onChange={handleSelectAll}
          inputProps={{
            'aria-label': 'select all'
          }}
        />
      </TableCell>
    )}
    {fields.map((el) => (
      <TableCell sx={{ py: 2 }} className="header-cell" key={el.label + 'head'}>
        <Box className="d-flex align-items-center" sx={{ maxHeight: '25px' }}>
          {el.labelIcon && (
            <Box
              sx={{
                mr: 0.75,
                ml: -0.5,
                my: 0,
                width: '20px',
                height: '20px'
              }}
            >
              {el.labelIcon}
            </Box>
          )}
          <CustomHeaderCell text={el.label} />
        </Box>
      </TableCell>
    ))}
    {actions && actions?.length > 0 && <TableCell sx={{ textAlign: 'right' }}>Actions</TableCell>}
  </TableRow>
);

export const BTableRow = ({
  row,
  fields,
  actions,
  index,
  selected,
  onRowSelect
}: {
  row: any;
  fields: ListField[];
  actions?: any[];
  index: number;
  selected: boolean;
  onRowSelect?: (id: number) => void;
}) => (
  <TableRow data-label={row.name} className={`table-row`} sx={rowStyle}>
    {onRowSelect && (
      <TableCell padding="checkbox" className={selected ? 'theme-background-light' : ''}>
        <Checkbox
          className={selected ? 'theme-color' : ''}
          size="small"
          checked={selected}
          inputProps={{
            'aria-labelledby': 'labelId'
          }}
          onChange={() => onRowSelect(parseFloat(row.id))}
        />
      </TableCell>
    )}
    {fields.map((el, cellIndex) => {
      const transform = el?.transform ? el?.transform(row[el.name], row) : '';
      return (
        <TableCell
          key={'tableRow' + index + el.label + 'Cell' + cellIndex}
          className={`cell ${selected ? 'theme-background-light' : ''}`}
          sx={{ verticalAlign: 'top' }}
        >
          <Box className="inner-cell">{transform || row[el.name] || ''}</Box>
        </TableCell>
      );
    })}
    {actions && actions?.length > 0 && (
      <TableCell width={'1px'}>
        <Box
          className="d-flex"
          sx={{
            textAlign: 'right',
            marginRight: '-16px'
          }}
        >
          {actions?.map(
            (action) =>
              typeof action.render === 'function' && (
                <Fragment key={action.name}>{action?.render(row)}</Fragment>
              )
          )}
        </Box>
      </TableCell>
    )}
  </TableRow>
);
